





























import { Component, Model, Vue } from 'vue-property-decorator';

@Component
export default class ViewControl extends Vue {
  @Model('input', { type: String }) value: 'list' | 'grid';

  select(view: 'list' | 'grid') {
    this.$emit('input', view);
  }
}
