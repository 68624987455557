export default function statComparator(field: string, desc: boolean) {
  return (a: any, b: any): number => {
    if (a[field] < b[field]) {
      if (desc) {
        return 1;
      }
      return -1;
    }
    if (a[field] > b[field]) {
      if (desc) {
        return -1;
      }
      return 1;
    }
    return 0;
  };
}
