






































































































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import InformationPanel from '@/shared/components/campaign/results/InformationPanel.vue';
import { Campaign } from '@/shared/store/campaign';
import { ImageReport, ReportType, CopyReport } from '@/shared/store/campaignReports';
import ImageResultsTable from '@/shared/components/campaign/results/ImageResultsTable.vue';
import CopyResultsTable from '@/shared/components/campaign/results/CopyResultsTable.vue';
import { getHalfSizeImageUrl, getImageUrl } from '@/shared/lib/imgixFunctions';
import TableNoResults from '@/shared/components/campaign/results/TableNoResults.vue';
import GridSortControl, { Sort } from '@/shared/components/campaign/results/GridSortControl.vue';
import ViewControl from '@/shared/components/campaign/results/ViewControl.vue';
import CreativeGridView from '@/shared/components/campaign/results/CreativeGridView.vue';
import statComparator from '@/shared/lib/campaignReports';
import ExampleCreativesModal from '@/shared/components/modals/ExampleCreativesModal.vue';

@Component({
  components: {
    ExampleCreativesModal,
    CreativeGridView,
    ViewControl,
    GridSortControl,
    TableNoResults,
    InformationPanel,
    ImageResultsTable,
    CopyResultsTable,
  },
})
export default class Assets extends Vue {
  $refs: {
    exampleCreativesModal: ExampleCreativesModal,
  }

  @Getter('campaign/campaignDetails') campaign: Campaign;

  @Getter('layout/isMobile') isMobile: boolean;

  currentView: string = 'grid';

  selectedCreative: any = {};

  selectedCreativeStats: any = {};

  mounted() {
    this.$store.dispatch('campaignReports/getReports', {
      campaignId: this.campaign.id,
      reports: [ReportType.Image, ReportType.Copy],
    }).then(() => {
      this.checkForPop();
    });
    if (this.isMobile) {
      this.currentView = 'list';
    }
  }

  checkForPop() {
    if (this.$route.query.displayAsset && typeof this.$route.query.displayAsset === 'string') {
      this.currentView = 'list';
      const imageId = this.$route.query.displayAsset.toString();
      this.$nextTick(() => {
        if (this.imagesAsCreatives && this.imagesAsCreatives.length > 0) {
          const creativeIndex = this.imagesAsCreatives.findIndex((c: any) => c.id === imageId);
          if (creativeIndex !== -1) {
            const creative = this.imagesAsCreatives[creativeIndex];
            this.rowClicked({
              ...creative,
              url: creative.screenshot,
            });
          }
        }
      });
    }
  }

  get sortableFields(): string[] {
    const sortableFields = ['impressions', 'interactions'];
    if (!this.isMobile) {
      sortableFields.push('engagement');
    }
    return sortableFields;
  }

  currentSort: Sort = { field: 'impressions', desc: true };

  get sortedImages() {
    return this.imagesAsCreatives.sort(statComparator(this.currentSort.field, this.currentSort.desc)).slice();
  }

  get imagesAsCreatives() {
    return (this.imageReports || []).map((i) => {
      const { impressions, interactions } = i.report;
      const engagement = impressions > 0 ? (interactions / impressions) * 100 : 0;
      return {
        id: i.id,
        networkType: 'Display',
        screenshot: getHalfSizeImageUrl(i.url),
        impressions,
        interactions,
        engagement,
      };
    });
  }

  get isUnlocked() {
    return this.$store.getters['campaignReports/unlocked'](this.campaign.id);
  }

  get imageReportsLoading() { // TODO: Error state if we are not loading and not loaded?
    return this.$store.getters['campaignReports/imageReportsLoading'](this.campaign.id) || !this.imageReportsLoaded;
  }

  get imageReportsLoaded() {
    return this.$store.getters['campaignReports/imageReportsLoaded'](this.campaign.id);
  }

  get imageReports(): ImageReport[] | undefined {
    return this.$store.getters['campaignReports/imageReports'](this.campaign.id);
  }

  get hasImageReports() {
    return this.imageReportsLoaded && this.imageReports!.length > 0;
  }

  get copyReportsLoading() { // TODO: Error state if we are not loading and not loaded?
    return this.$store.getters['campaignReports/copyReportsLoading'](this.campaign.id) || !this.copyReportsLoaded;
  }

  get copyReports(): CopyReport[] | undefined {
    return this.$store.getters['campaignReports/copyReports'](this.campaign.id);
  }

  get copyReportsLoaded() {
    return this.$store.getters['campaignReports/copyReportsLoaded'](this.campaign.id);
  }

  get hasCopyReports() {
    return this.copyReportsLoaded && this.copyReports!.length > 0;
  }

  get userImages() {
    return this.campaign.ugc!.images;
  }

  get hasUserImages() {
    return this.userImages.length > 0;
  }

  rowClicked(item: any) {
    this.selectedCreative = {
      ...item,
      image: getImageUrl(item.url),
    };
    this.selectedCreativeStats = {
      impressions: item.impressions,
      interactions: item.interactions,
      engagement: item.engagement,
    };
    this.$refs.exampleCreativesModal.show();
  }

  get hideEngagementText() {
    return this.$mq === 'xs' || this.$mq === 'sm';
  }
}
