













































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SortableField extends Vue {
  @Prop({ required: true }) field: string;

  @Prop({ required: true }) label: string;

  @Prop({ default: 'center' }) justify: string;

  @Prop({ default: '' }) icon: string[] | string;

  @Prop({ required: true }) sortBy: string;

  @Prop({ required: true }) sortDesc: boolean;

  @Prop({ default: false }) collapseLabel: boolean;

  @Prop({ default: true }) canSort: boolean;

  get hasIcon() {
    return this.icon.length > 0;
  }

  get isFaIcon() {
    return Array.isArray(this.icon);
  }

  get isActive() {
    return this.sortBy === this.field;
  }
}
