






































































































import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import sessionUniqueId from '@/shared/lib/sessionUniqueId';
import LoadingIndicator from '@/shared/components/common/LoadingIndicator.vue';

// This magic number is needed to make sure the transition events actually fire.  If we update the
// min/max-height property too soon, we the transition doesn't actually fire.
const ResizeTransitionDelayMilliseconds = 25;

@Component({
  components: { LoadingIndicator },
})
export default class InformationPanel extends Vue {
  $refs!: {
    informationPanel: HTMLElement,
  }

  @Prop({ required: true }) title: string;

  @Prop({ default: () => ({}) }) panes: { [key: string]: string };

  @Prop({ default: false }) loading: boolean;

  @Prop({ default: '' }) wrapTitleAddon: string;

  helpActive: boolean = false;

  panelHeight: number = 0;

  resizeId: number = 0;

  tabIndex: number = 0;

  uniqueId: string = '';

  created() {
    this.uniqueId = sessionUniqueId('information-panel');
  }

  mounted() {
    this.resetHeight();
    this.$root.$on('InformationPanel::help-activated', this.handleHelpActivated);
    this.$root.$on('InformationPanel::navigated', this.handleNavigated);
    window.addEventListener('resize', this.resetHeight, { passive: true });
  }

  beforeDestroy() {
    this.$root.$off('InformationPanel::help-activated', this.handleHelpActivated);
    this.$root.$off('InformationPanel::navigated', this.handleNavigated);
    window.removeEventListener('resize', this.resetHeight);
  }

  handleHelpActivated(id: string) {
    if (id !== this.uniqueId) {
      this.closeHelp();
    }
  }

  handleNavigated() {
    this.closeHelp();
  }

  setActivePane(selectedPane: string) {
    const paneNames = Object.keys(this.panes);
    const paneIndex = paneNames.indexOf(selectedPane);
    if (paneIndex !== -1) {
      this.tabIndex = paneIndex;
    }
  }

  get hasTitleAddon() {
    return !!this.$slots['title-addons'];
  }

  get wrapTitleAddonExtraClass() {
    if (this.wrapTitleAddon && !this.hasTabs && this.hasTitleAddon) {
      return `wrap wrap-${this.wrapTitleAddon}`;
    }
    return '';
  }

  get hasTabs() {
    return Object.keys(this.panes).length > 0;
  }

  resetHeight() {
    this.handleResizeTransitionComplete();
    const { informationPanel } = this.$refs;
    this.panelHeight = informationPanel.clientHeight;
  }

  /* eslint-disable no-param-reassign */
  setMinHeight(style: CSSStyleDeclaration, from: number, to: number) {
    if (style.minHeight) {
      style.minHeight = `${to}px`;
    } else {
      style.minHeight = `${from}px`;
      setTimeout(() => {
        style.minHeight = `${to}px`;
      }, ResizeTransitionDelayMilliseconds);
    }
  }

  setMaxHeight(style: CSSStyleDeclaration, from: number, to: number) {
    if (style.maxHeight) {
      style.maxHeight = `${to}px`;
    } else {
      style.maxHeight = `${from}px`;
      setTimeout(() => {
        style.maxHeight = `${to}px`;
      }, ResizeTransitionDelayMilliseconds);
    }
  }
  /* eslint-enable */

  resizeInformationPanel() {
    const { informationPanel } = this.$refs;
    const newHeight = informationPanel.clientHeight;
    if (this.panelHeight !== newHeight) {
      this.resizeId += 1;
      const k = this.resizeId;
      const maxHeightHandler = (e: TransitionEvent) => {
        // We only execute the handler once...
        if (e.propertyName === 'min-height' || e.propertyName === 'max-height') {
          informationPanel.removeEventListener('transitionend', maxHeightHandler);
          if (this.resizeId !== k) {
            // We are no longer responsible...
            return;
          }
          this.handleResizeTransitionComplete();
        }
      };
      informationPanel.addEventListener('transitionend', maxHeightHandler, { passive: true });
      if (this.panelHeight > newHeight) {
        this.setMinHeight(informationPanel.style, this.panelHeight, newHeight);
      } else {
        this.setMaxHeight(informationPanel.style, this.panelHeight, newHeight);
      }
    } else {
      this.handleResizeTransitionComplete();
    }
  }

  handleResizeTransitionComplete() {
    this.resetResizeTransitionStyles();
    const { informationPanel } = this.$refs;
    this.panelHeight = informationPanel.clientHeight;
    if (this.helpActive) {
      informationPanel.classList.add('help-expanded');
    } else {
      informationPanel.classList.remove('help-expanded');
    }
  }

  resetResizeTransitionStyles() {
    const { informationPanel } = this.$refs;
    if (informationPanel) {
      informationPanel.style.minHeight = '';
      informationPanel.style.maxHeight = '';
    }
  }

  handleContentEnter() {
    this.resizeInformationPanel();
  }

  closeHelp() {
    if (!this.helpActive) {
      return;
    }
    this.resetResizeTransitionStyles();
    const { informationPanel } = this.$refs;
    this.panelHeight = informationPanel.clientHeight;
    this.helpActive = false;
  }

  openHelp() {
    if (this.helpActive) {
      return;
    }
    this.resetResizeTransitionStyles();
    const { informationPanel } = this.$refs;
    this.panelHeight = informationPanel.clientHeight;
    this.helpActive = true;
    this.$root.$emit('InformationPanel::help-activated', this.uniqueId);
  }

  toggleHelp() {
    if (this.helpActive) {
      this.closeHelp();
    } else {
      this.openHelp();
    }
  }
}
