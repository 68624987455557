






import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  axisBottom as d3axisBottom,
  axisLeft as d3axisLeft,
  bisector as d3bisector,
  event as d3event,
  format as d3format,
  line as d3line,
  max as d3max,
  min as d3min,
  mouse as d3mouse,
  scaleLinear as d3scaleLinear,
  scaleTime as d3scaleTime,
  select as d3select,
  timeDay as d3timeDay,
  timeFormat as d3timeFormat,
  touches as d3touches,
} from 'd3';
import { differenceInCalendarDays, differenceInHours, startOfDay } from 'date-fns';
import uuid from '@/shared/lib/uuid';
import { ordinal } from '@/shared/lib/math';

@Component
export default class LineChart extends Vue {
  @Prop({ required: true }) dataPoints:
    { date: Date, value: number }[] | { at: number, value: number }[];

  @Prop({ default: 0 }) start: number;

  @Prop({ default: 0 }) end: number;

  @Prop({ default: () => new Date(0) }) startDate: Date;

  @Prop({ default: () => new Date(0) }) endDate: Date;

  @Prop({ default: false }) genericLabel: boolean;

  @Prop({ default: 300 }) defaultYMax: number;

  @Prop({ default: false }) solidTodayLine: boolean;

  chartId: string = '';

  created() {
    this.chartId = `line-chart-${uuid()}`;
    window.addEventListener('resize', this.calculatePath, { passive: true });
  }

  mounted() {
    this.$nextTick(this.calculatePath);
    window.addEventListener('touchmove', this.x);
  }

  x() {}

  destroyed() {
    window.removeEventListener('resize', this.calculatePath);
    window.removeEventListener('touchmove', this.x);
  }

  get isDateChart() {
    return this.startDate.getTime() > 0;
  }

  chartWidth: number = 0;

  chartHeight: number = 0;

  chartSize: 'sm' | 'md' | 'lg' = 'sm';

  calculatePath() {
    const chartDiv = document.getElementById(this.chartId);
    if (!chartDiv) {
      return;
    }
    chartDiv.innerHTML = '';

    const margin = {
      top: 10,
      right: 7,
      bottom: this.isDateChart ? 60 : 40,
      left: 8,
    };

    this.chartWidth = chartDiv.clientWidth;
    if (this.chartWidth < 350) {
      this.chartSize = 'sm';
      this.chartHeight = (this.chartWidth * 2.75) / 5;
    } else if (this.chartWidth < 600) {
      this.chartSize = 'md';
      this.chartHeight = (this.chartWidth * 2) / 5;
      margin.bottom = this.isDateChart ? 65 : 40;
    } else {
      this.chartSize = 'lg';
      this.chartHeight = (this.chartWidth * 1.75) / 5;
      margin.bottom = this.isDateChart ? 65 : 40;

      const maxYValue = d3max(this.yMapper(margin).ticks(this.isDateChart ? 4 : 2));
      margin.left = 5 + `${maxYValue}`.length * 5;
    }

    const svg = d3select(chartDiv)
      .append('svg')
      .attr('width', this.chartWidth)
      .attr('height', this.chartHeight)
      .attr('viewBox',
        `0 0 ${this.chartWidth} ${this.chartHeight}`)
      .append('g')
      .attr('transform', `translate(${margin.left} ${margin.top})`);

    let processed: { data: any[], line: any, xMapper: Function, yMapper: Function, hoverMapper: Function };
    if (this.isDateChart) {
      processed = this.processAsDate(this.chartWidth, this.chartHeight, margin, svg);
    } else {
      processed = this.processAsNumber(this.chartWidth, this.chartHeight, margin, svg);
    }
    const {
      data, line, xMapper, yMapper, hoverMapper,
    } = processed;

    const past = svg.append('g');
    const future = svg.append('g');
    const today = svg.append('g');
    this.updateLines(data, data.length, line, past, future, today);

    const latest = svg.append('g')
      .attr('class', 'latest');
    if (this.isDateChart && data.length === 1) {
      latest.append('circle')
        .attr('r', '6')
        .attr('cx', xMapper(data[0].date))
        .attr('cy', yMapper(data[0].value));
    }

    const focus = svg.append('g')
      .attr('class', 'focus')
      .style('display', 'none');
    focus
      .append('circle')
      .attr('r', '6');

    const arrow = svg.append('g')
      .attr('class', 'arrow')
      .style('display', 'none');
    arrow
      .append('path')
      .attr('d', 'M1 1l5 14 5-14-5 3z');

    const connector = svg.append('g')
      .attr('class', 'connector')
      .style('display', 'none');
    connector
      .append('line');

    let prevIdx = -1;
    const mouseListener = (): [boolean, boolean] => {
      const found = hoverMapper();
      if (!found) {
        return [false, false];
      }
      const {
        i, d, xCoord, yCoord,
      } = found;
      if (prevIdx === i) {
        return [true, false];
      }
      prevIdx = i;
      this.updateLines(data, i, line, past, future, today);

      connector.select('line')
        .attr('x1', xCoord)
        .attr('y1', yCoord + 6) // + focus circle radius
        .attr('x2', xCoord)
        .attr('y2', this.chartHeight - margin.bottom + 12);
      focus.attr('transform', `translate(${xCoord} ${yCoord})`);
      arrow.attr('transform', `translate(${xCoord - 6} ${this.chartHeight - margin.bottom})`);
      this.$emit('select', d);
      return [true, true];
    };

    const exposeFocusIndicators = (evtType: string) => {
      latest.style('display', 'none');
      focus.style('display', null);
      if (evtType.startsWith('touch')) {
        connector.style('display', null);
      } else {
        arrow.style('display', null);
      }
    };

    const hideFocusIndicators = () => {
      latest.style('display', null);
      focus.style('display', 'none');
      arrow.style('display', 'none');
      connector.style('display', 'none');
    };

    const clearFocus = () => {
      prevIdx = -1;
      hideFocusIndicators();
      this.updateLines(data, data.length, line, past, future, today);
      this.$emit('deselect');
    };

    const hoverHandler = () => {
      const [found, update] = mouseListener();
      if (!found) {
        clearFocus();
      }
      if (found && update) {
        exposeFocusIndicators(d3event.type);
      }
      return true;
    };

    svg.append('rect')
      .attr('class', 'overlay')
      .attr('width', this.chartWidth - margin.left)
      .attr('height', this.chartHeight - margin.top)
      .on('zoom', () => null)
      .on('mouseout', () => {
        clearFocus();
      })
      .on('mouseover mousemove touchstart', hoverHandler, true)
      .on('touchend', () => {
        if (this.clearFocusTimeout) {
          clearTimeout(this.clearFocusTimeout);
        }
        this.clearFocusTimeout = setTimeout(() => {
          clearFocus();
        }, this.clearFocusMs);
        return true;
      });
    svg.selectAll('rect').on('touchmove', hoverHandler, true);
  }

  clearFocusMs: number = 2000;

  clearFocusTimeout: number = 0;

  private todayIdx(data: any[]) {
    return this.isDateChart
      ? data.findIndex((datum: { date: Date }) => datum.date.getTime() === startOfDay(new Date()).getTime())
      : -1;
  }

  private updateLines(data: any[], i: number, line:any, past:any, future:any, today:any) {
    const todayIdx = this.solidTodayLine ? -1 : this.todayIdx(data);
    past.selectAll('path').remove();
    const pastData = todayIdx === -1
      ? data.slice(0, i)
      : data.slice(0, Math.min(i, todayIdx));
    past.append('path')
      .datum(pastData)
      .attr('class', 'line')
      .attr('d', line);
    future.selectAll('path').remove();
    const futureData = todayIdx === -1
      ? data.slice(i - 1, data.length)
      : data.slice(i - 1, todayIdx);
    future.append('path')
      .datum(futureData)
      .attr('class', 'line dimmed')
      .attr('d', line);
    today.selectAll('path').remove();
    const todayData = todayIdx === -1
      ? []
      : data.slice(todayIdx - 1, data.length);
    today.append('path')
      .datum(todayData)
      .attr('class', `line dotted ${i <= todayIdx ? 'dimmed' : ''}`)
      .attr('d', line);
  }

  private processAsDate(
    width: number,
    height: number,
    margin: { top: number; left: number; bottom: number; right: number },
    svg: any,
  ) {
    const small = this.chartSize === 'sm';
    const large = this.chartSize === 'lg';

    const data = (this.dataPoints as { date: Date, value: number }[])
      .filter((datum: { date: Date, value: number }) => datum.date.getTime() >= startOfDay(this.startDate).getTime())
      .sort((a, b) => a.date.getTime() - b.date.getTime())
      .map((datum: { date: Date, value: number }) => ({
        date: startOfDay(datum.date),
        value: datum.value,
      }));

    // X Axis
    const { startDate, durationDays } = this;
    const endDate = this.calculatedEndDate;
    const xLeft = large ? margin.left : 0;
    const xMapper = d3scaleTime()
      .range([xLeft, width - margin.left - margin.right])
      .domain([startOfDay(startDate), startOfDay(endDate)]);
    const xAxis = d3axisBottom(xMapper)
      .ticks(d3timeDay)
      .tickSize(16)
      .tickPadding(-12)
      .tickFormat(this.genericLabel
        ? (d:any, i: number) => `${ordinal(i + 1)} day`
        : d3timeFormat('%b %-d'));
    const xAxisG = svg.append('g')
      .attr('class', `x axis date ${large ? 'large-view' : ''} ${small ? 'small-view' : ''}`)
      .attr('transform', `translate(0 ${(height - margin.bottom + 16)})`)
      .call(xAxis);
    this.classifyTicksForDuration(durationDays, xAxisG);
    xAxisG.selectAll('text')
      .call((t: any) => {
        t.each(function x(this: any) {
          const self = d3select(this);
          const words = self.text().split(' ');
          self.text('');
          self.append('tspan')
            .attr('x', 0)
            .attr('dy', '1.1em')
            .text(words[0]);
          self.append('tspan')
            .attr('x', 0)
            .attr('dy', '1.1em')
            .text(words[1]);
        });
      });

    // Y Axis
    const yMapper = this.yMapper(margin);
    const tickPadding = 10;
    const yAxis = d3axisLeft(yMapper)
      .ticks(large ? 4 : 3)
      .tickPadding(tickPadding)
      .tickFormat(d3format(','));
    const yAxisG = svg.append('g')
      .attr('class', `y axis date ${large ? 'large-view' : ''} ${small ? 'small-view' : ''}`)
      .attr('transform', `translate(${large ? `${margin.left} 0` : '0 0'})`)
      .call(yAxis.tickSize(-width + margin.right));
    let dy = large ? 0 : 10;
    const dx = large ? 0 : tickPadding;
    if (small) {
      dy = 8;
    }
    yAxisG.selectAll('text').attr('transform', `translate(${dx} ${dy})`);

    const line = d3line()
      .x((d: { date: Date }) => xMapper(d.date))
      .y((d: { value: number }) => yMapper(d.value));

    return {
      data,
      line,
      xMapper,
      yMapper,
      hoverMapper: () => {
        if (data.length <= 1) {
          return null;
        }
        let i: number;
        let datum: { date: number; value: number };
        const x0 = ['mouseover', 'mousemove'].includes(d3event.type)
          ? xMapper.invert(d3mouse(d3event.currentTarget)[0])
          : xMapper.invert(d3touches(d3event.currentTarget)[0][0]);
        const bisector = d3bisector((d: { date: Date }) => d.date).left;
        i = bisector(data, x0, 1);
        if (i === data.length) {
          i = data.length - 1;
          if (differenceInHours(x0, data[i].date) > 12) {
            return null;
          }
        }
        const d0 = data[i - 1];
        const d1 = data[i];
        if (x0 - d0.date.getTime() > d1.date.getTime() - x0) {
          i += 1;
          datum = d1;
        } else {
          datum = d0;
        }
        return {
          i, d: datum, xCoord: xMapper(datum.date), yCoord: yMapper(datum.value),
        };
      },
    };
  }

  private classifyTicksForDuration(durationDays: number, xAxisG: any) {
    function isNotClassified(this: any) {
      return this.classList.length === 1;
    }
    const selection = xAxisG.selectAll('g');
    selection
      .filter((d: any, i: number) => i === 0 || i === selection.nodes().length - 1)
      .classed('major', true);
    if (durationDays <= 8) {
      const minor = xAxisG.selectAll('g');
      minor
        .filter(isNotClassified)
        .classed('minor', true);
      xAxisG.selectAll('g')
        .filter((d: any, i: number) => [0, 2, 5, 7].includes(i))
        .classed('labeled', true);
    } else {
      const minor = xAxisG.selectAll('g');
      minor
        .filter((d: any, i: number) => i % 7 === 0 && i < minor.nodes().length - 5)
        .classed('minor', true)
        .classed('labeled', durationDays <= 31);
      minor
        .filter((d: any, i: number) => i % 30 === 0 && i < minor.nodes().length - 7)
        .classed('labeled', true);
      const tiny = xAxisG.selectAll('g');
      tiny
        .filter(isNotClassified)
        .classed('tiny', true);
    }
  }

  private processAsNumber(
    width: number,
    height: number,
    margin: { top: number; left: number; bottom: number; right: number },
    svg: any,
  ) {
    const small = this.chartSize === 'sm';
    const large = this.chartSize === 'lg';

    const data = this.dataPoints as { at: number, value: number }[];
    data.sort((a, b) => a.at - b.at);

    // X Axis
    let { start, end } = this;
    if (start === 0) {
      start = d3min(data, (d: { at: number }) => d.at);
    }
    if (end === 0) {
      end = d3max(data, (d: { at: number }) => d.at);
    }
    const xLeft = large ? margin.left : 0;
    const xMapper = d3scaleLinear()
      .range([xLeft, width - margin.left - margin.right])
      .domain([start, end]);
    const xAxis = d3axisBottom(xMapper)
      .ticks(30)
      .tickSize(16)
      .tickPadding(-12)
      .tickFormat((d: any) => `${d3format('d')(d)}s`);
    const xAxisG = svg.append('g')
      .attr('class', `x axis number ${large ? 'large-view' : ''} ${small ? 'small-view' : ''}`)
      .attr('transform', `translate(0 ${height - margin.bottom + 16})`)
      .call(xAxis);
    xAxisG.selectAll('text');
    this.classifyTicksForIntervalSize(10, xAxisG);

    // Y Axis
    const yMapper = this.yMapper(margin);
    const tickPadding = 10;
    const yAxis = d3axisLeft(yMapper)
      .ticks(2)
      .tickPadding(15)
      .tickFormat(d3format(','));
    const yAxisG = svg.append('g')
      .attr('class', `y axis number ${large ? 'large-view' : ''} ${small ? 'small-view' : ''}`)
      .attr('transform', `translate(${large ? `${margin.left} 0` : '0 0'})`)
      .call(yAxis.tickSize(-width + margin.right));
    let dy = large ? 0 : 10;
    const dx = large ? 0 : tickPadding;
    if (small) {
      dy = 8;
    }
    yAxisG.selectAll('text').attr('transform', `translate(${dx} ${dy})`);

    const line = d3line()
      .x((d: { at: number }) => xMapper(d.at))
      .y((d: { value: number }) => yMapper(d.value));

    return {
      data,
      line,
      xMapper,
      yMapper,
      hoverMapper: () => {
        if (data.length <= 1) {
          return null;
        }
        let i: number;
        let datum: { at: number; value: number };
        const x0 = ['mouseover', 'mousemove'].includes(d3event.type)
          ? xMapper.invert(d3mouse(d3event.currentTarget)[0])
          : xMapper.invert(d3touches(d3event.currentTarget)[0][0]);
        const bisector = d3bisector((d: { at: number }) => d.at).left;
        i = bisector(data, x0, 1);
        if (i === data.length) {
          i = data.length - 1;
        }
        const d0 = data[i - 1];
        const d1 = data[i];
        if (x0 - d0.at > d1.at - x0) {
          i += 1;
          datum = d1;
        } else {
          datum = d0;
        }
        return {
          i, d: datum, xCoord: xMapper(datum.at), yCoord: yMapper(datum.value),
        };
      },
    };
  }

  get calculatedEndDate() {
    const { dataPoints } = this;
    let { endDate } = this;
    if (dataPoints.length > 0) {
      const max = d3max(dataPoints, (d: { date: Date }) => d.date);
      endDate = new Date(Math.max(endDate.getTime(), max.getTime()));
    }
    return endDate;
  }

  get durationDays() {
    return differenceInCalendarDays(this.calculatedEndDate, this.startDate);
  }

  private yMapper(
    margin: { top: number, bottom: number },
  ) {
    const dataValues = (this.dataPoints as { value: number }[]).map(({ value }) => value);
    const minValue = d3min(dataValues);
    const maxValue = d3max(dataValues);

    const height = this.chartHeight;

    let domain: number[];
    let nice: number;
    if (this.isDateChart) {
      domain = [0, dataValues.length ? maxValue : this.defaultYMax];
      nice = 4;
      if (dataValues.length > 1) {
        const span = maxValue - minValue;
        const minCollapseFactor = 0.5;
        domain = [Math.max(0, minValue - minCollapseFactor * span), maxValue];
      }
      if (this.chartSize !== 'lg') {
        nice = 3;
        let overlap = false;
        const labelBuffer = 0.8 * domain[1];
        const leftBufferIdx = Math.round(0.1 * this.durationDays);
        for (let i = 0; i < leftBufferIdx && i < dataValues.length; i += 1) {
          if (dataValues[i] >= labelBuffer) {
            overlap = true;
          }
        }
        if (overlap) {
          domain[1] += 0.2 * domain[1];
        }
      }
    } else {
      domain = [0, 10];
      nice = 2;
      if (dataValues.length) {
        domain = [0, maxValue];
      }
    }
    return d3scaleLinear()
      .range([height - margin.bottom, margin.top])
      .domain(domain)
      .nice(nice);
  }

  private classifyTicksForIntervalSize(interval: number, xAxisG: any) {
    const selection = xAxisG.selectAll('g');
    selection
      .filter((d: any, i: number) => i === 0 || i === selection.nodes().length - 1)
      .classed('labeled', true)
      .classed('major', true);
    const minor = xAxisG.selectAll('g');
    minor
      .filter((d: any, i: number) => i > 0 && i < minor.nodes().length - 1 && (i + 1) % interval === 0)
      .classed('labeled', true)
      .classed('minor', true);
    const tiny = xAxisG.selectAll('g');
    tiny
      .filter((d: any, i: number) => i > 0 && i < tiny.nodes().length - 1 && (i + 1) % interval !== 0)
      .classed('tiny', true);
  }
}
