


























import { Component, Prop, Vue } from 'vue-property-decorator';
import InstagramStoryCreative from '@/shared/components/common/InstagramStoryCreative.vue';
import InstagramFeedCreative from '@/shared/components/common/InstagramFeedCreative.vue';
import LoadingBackground from '@/shared/components/common/LoadingBackground.vue';
import { Creative_Instagram_Placement as Placement, FacebookPage } from '@/shared/gen/messages.pisa';
import * as imgixFunctions from '@/shared/lib/imgixFunctions';
import DisabledAdOverlay from '@/shared/components/common/DisabledAdOverlay.vue';

@Component({
  mixins: [],
  components: {
    DisabledAdOverlay,
    InstagramFeedCreative,
    InstagramStoryCreative,
    LoadingBackground,
  },
  props: {},
  validations: {},
})
export default class InstagramTemplate extends Vue {
  @Prop({ required: true })
  creative: any;

  @Prop({ default: false })
  disablePointer: boolean;

  @Prop({ default: false })
  disabled: boolean;

  @Prop({ default: () => {} })
  syncedPage: FacebookPage;

  get component() {
    if (this.creative.instagram.placement === Placement.STORY) {
      return 'InstagramStoryCreative';
    }
    return 'InstagramFeedCreative';
  }

  get primaryText() {
    return this.creative.instagram.primaryText;
  }

  get callToAction() {
    return this.creative.instagram.callToAction;
  }

  get imageUrl() {
    return imgixFunctions.getImgixSizeQuerystring(this.creative.instagram.imageUrl, 300, 300);
  }

  get colorPalette() {
    return this.creative.instagram.colorPalette;
  }

  get userDisabled() {
    if (this.creative.customization) {
      return !this.creative.customization.enabled;
    }
    return false;
  }

  handleAdClick() {
    this.$emit('click');
  }
}
