
















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import CreativeGridView from '@/shared/components/campaign/results/CreativeGridView.vue';
import CreativeControls from '@/shared/components/campaign/results/CreativeControls.vue';
import CreativeListView from '@/shared/components/campaign/results/CreativeListView.vue';
import ExampleCreativesModal from '@/shared/components/modals/ExampleCreativesModal.vue';
import { FacebookPage, LandingPage } from '@/shared/gen/messages.pisa';
import TableNoResults from '@/shared/components/campaign/results/TableNoResults.vue';
import statComparator from '@/shared/lib/campaignReports';
import { Sort } from '@/shared/components/campaign/results/GridSortControl.vue';

@Component({
  components: {
    TableNoResults,
    ExampleCreativesModal,
    CreativeGridView,
    CreativeControls,
    CreativeListView,
  },
})
export default class CreativePerformance extends Vue {
  $refs!: {
    exampleCreativesModal: any,
  };

  @Getter('layout/isMobile') isMobile: boolean;

  @Getter('layout/isTablet') isTablet: boolean;

  selectedCreative: any = {};

  selectedCreativeStats: any = {};

  currentView = 'grid';

  currentSort: Sort = { field: 'impressions', desc: true };

  selectedFilterFields: string[] = [];

  @Prop({ default: '' })
  creativeType: string;

  @Prop({ default: () => {} })
  creatives: any[];

  @Prop({ default: () => {} })
  filterFields: any[];

  @Prop({ default: () => {} })
  landingPages: LandingPage[];

  @Prop({ default: true })
  isUnlocked: boolean;

  @Prop({ default: false })
  isEnded: boolean;

  @Prop({ default: () => {} })
  syncedPage: FacebookPage;

  get syncedFacebookPageId() {
    if (this.syncedPage && this.syncedPage.pageId) {
      return this.syncedPage.pageId;
    }
    return '';
  }

  get sortableFields() {
    const sortableFields = ['impressions', 'interactions'];
    if (!this.isMobile) {
      sortableFields.push('engagement');
    }
    return sortableFields;
  }

  mounted() {
    this.filterFields.forEach((field) => {
      this.selectedFilterFields.push(field.objective);
    });
    if (this.isMobile) {
      this.currentView = 'list';
    }
  }

  get noResultsText() {
    return `No ${this.creativeType} ad performance stats to display${this.isEnded || this.hasStats ? '' : ' yet'}.`;
  }

  setCurrentView(displayType: 'grid' | 'list') {
    this.currentView = displayType;
  }

  sortingChange(newSortValue: any) {
    const { field, desc } = newSortValue;
    this.currentSort = { field, desc };
  }

  filterChange(newFilterValue: any) {
    this.selectedFilterFields = newFilterValue;
  }

  viewChange(newView: string) {
    this.currentView = newView;
  }

  get sortedCreatives() {
    return this.filteredCreatives.sort(statComparator(this.currentSort.field, this.currentSort.desc)).slice();
  }

  get filteredCreatives() {
    return this.creatives.filter((creative) => this.selectedFilterFields.indexOf(creative.objective) !== -1);
  }

  get hasStats() {
    return this.creatives.length > 0;
  }

  get hasCreatives() {
    return this.filteredCreatives.length > 0;
  }

  get displaySortSelect() {
    return this.isUnlocked && this.creatives.length > 0;
  }

  viewCreative(item: any) {
    this.selectedCreative = item.ad;
    this.selectedCreative.id = item.id;
    this.selectedCreativeStats = {
      impressions: item.impressions,
      interactions: item.interactions,
      engagement: item.engagement,
    };
    this.$refs.exampleCreativesModal.show();
  }
}
