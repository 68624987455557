






















import {
  Component, Model, Prop, Vue,
} from 'vue-property-decorator';

export interface Sort {
  field: string;
  desc: boolean;
}

@Component
export default class GridSortControl extends Vue {
    @Prop({ required: true }) sortableFields: string[];

    @Model('input') value: Sort;

    sort: Sort;

    created() {
      this.sort = this.value;
    }

    get sortDescCopy() {
      return this.sort.desc ? 'High to Low' : 'Low to High';
    }

    get sortBy() {
      return `${this.sort.field.slice(0, 1).toUpperCase()}${this.sort.field.slice(1)}: ${this.sortDescCopy}`;
    }

    set sortBy(value: string) {
      const options = value.split(' ');
      this.sort.field = options[0].slice(0, options[0].length - 1).toLowerCase();
      this.sort.desc = options[1] === 'High';
      this.$emit('input', this.sort);
    }

    get sortOptions() {
      const options: string[] = [];
      this.sortableFields.forEach((field) => {
        let option = `${field.slice(0, 1).toUpperCase()}${field.slice(1)}: High to Low`;
        options.push(option);
        option = `${field.slice(0, 1).toUpperCase()}${field.slice(1)}: Low to High`;
        options.push(option);
      });
      return options;
    }
}
