



































































import { BvTableFieldArray } from 'bootstrap-vue/src/components/table';
import abbreviate from 'number-abbreviate';
import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import SortableField from '@/shared/components/campaign/results/SortableField.vue';
import { Sort } from '@/shared/components/campaign/results/GridSortControl.vue';

@Component({
  components: {
    SortableField,
  },
})
export default class Table extends Vue {
  @Getter('layout/isMobile') isMobile: boolean;

  @Prop({ required: true })
  items: any[];

  @Prop({ required: true })
  fields: BvTableFieldArray;

  @Prop({ default: false })
  clickable: boolean;

  @Prop({ default: true })
  canSort: boolean;

  @Prop({ default: null })
  theadClass: any;

  @Prop({ default: null })
  theadTrClass: any;

  @Prop({ default: null })
  tbodyClass: any;

  @Prop({ default: null })
  tbodyTrClass: any;

  @Prop({ default: false })
  hideHead: boolean;

  @Prop({ default: true })
  forceLabelWidth: boolean;

  @Prop({ default: false })
  showEmpty: boolean;

  @Prop({ default: 'impressions' })
  sortBy: string;

  @Prop({ default: true })
  sortDesc: boolean;

  sort: Sort;

  created() {
    this.sort = { field: this.sortBy, desc: this.sortDesc };
  }

  get computedFields() {
    return [
      ...this.fields.map((i) => (typeof i === 'string' ? { key: i } : i)),
      {
        key: 'impressions',
        icon: ['far', 'eye'],
        collapseLabel: true,
        sortable: this.canSort,
        sortDirection: 'desc',
        formatter: this.localeStringFormatter,
        tdClass: ['impressions'],
      }, {
        key: 'interactions',
        icon: 'tappy',
        collapseLabel: true,
        sortable: this.canSort,
        sortDirection: 'desc',
        formatter: this.localeStringFormatter,
        tdClass: ['interactions'],
      }, {
        key: 'engagement',
        icon: 'stonks',
        collapseLabel: true,
        sortable: this.canSort,
        sortDirection: 'desc',
        formatter: this.fixedPercentFormatter,
        thClass: ['d-none', 'd-sm-table-cell'],
        tdClass: ['engagement', 'd-none', 'd-sm-table-cell'],
      },
    ].map((i, j) => {
      const override = this.hideHead ? { label: '' } : {};
      return {
        ...i,
        ...override,
        thClass: [...(i.thClass || []), `nth-${j}`],
      };
    });
  }

  localeStringFormatter(n: number) {
    return n > 99999 && this.isMobile ? abbreviate(n, 1) : n.toLocaleString();
  }

  fixedPercentFormatter(n: number) {
    if (n <= 0) {
      return '0%';
    }
    return `${n.toFixed(2)}%`;
  }
}
