























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import abbreviate from 'number-abbreviate';
import FacebookTemplate from '@/shared/components/common/FacebookTemplate.vue';
import InstagramTemplate from '@/shared/components/common/InstagramTemplate.vue';
import { FacebookPage } from '@/shared/gen/messages.pisa';
import LoadingBackground from '@/shared/components/common/LoadingBackground.vue';

@Component({
  components: {
    FacebookTemplate,
    InstagramTemplate,
    LoadingBackground,
  },
})
export default class CreativeGridView extends Vue {
  @Getter('layout/isMobile') isMobile: boolean;

  @Getter('layout/isTablet') isTablet: boolean;

  @Prop({ default: () => [] })
  creatives: any[];

  @Prop({ default: () => {} })
  syncedPage: FacebookPage;

  localeStringFormatter(n: number) {
    return n > 99999 && this.isMobile ? abbreviate(n, 1) : n.toLocaleString();
  }

  engagementRateFormatter(n: number) {
    return `${n.toFixed(2)}%`;
  }

  get hasCreatives() {
    return this.creatives.length > 0;
  }
}
