































































































































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import {
  AppleMusic, BandLab, Deezer, normalizedDomainFromURL, Spotify,
} from '@/shared/lib/domains';
import { Campaign } from '@/shared/store/campaign';
import { CampaignReport, ReportAudio, ReportType } from '@/shared/store/campaignReports';
import AbbreviatedLabeledValue from '@/shared/components/campaign/results/AbbreviatedLabeledValue.vue';
import InformationPanel from '@/shared/components/campaign/results/InformationPanel.vue';
import LabeledValue from '@/shared/components/campaign/results/LabeledValue.vue';
import LineChart from '@/shared/components/campaign/results/LineChart.vue';

const HELP_TEXT_RETAILERS = [
  [AppleMusic, 'Apple Music'],
  [BandLab, 'BandLab'],
  [Deezer, 'Deezer'],
  [Spotify, 'Spotify'],
  ['soundcloud.com', 'SoundCloud'],
  ['soundcloud.app.goo.gl', 'SoundCloud'],
  ['youtube.com', 'YouTube'],
  ['youtu.be', 'YouTube'],
];

const HELP_TEXT_RETAILER_ORDER = [
  'BandLab', 'Spotify', 'Apple Music', 'Deezer',
];

const sortHelpTextRetailerNames = (list: string[]): string[] => list.sort((l, r) => {
  const lIndex = HELP_TEXT_RETAILER_ORDER.indexOf(l);
  const rIndex = HELP_TEXT_RETAILER_ORDER.indexOf(r);
  if (lIndex === rIndex) {
    const lLower = l.toLowerCase();
    const rLower = r.toLowerCase();
    if (lLower < rLower) {
      return -1;
    }
    if (lLower > rLower) {
      return 1;
    }
    return 0;
  }
  if (lIndex === -1) {
    return 1;
  }
  if (lIndex < rIndex || rIndex === -1) {
    return -1;
  }
  return 1;
});

@Component({
  components: {
    AbbreviatedLabeledValue,
    InformationPanel,
    LabeledValue,
    LineChart,
  },
})
export default class SongPreviewsAndFeedback extends Vue {
  @Getter('campaign/campaignDetails') campaign: Campaign;

  mounted() {
    this.$store.dispatch('campaignReports/getReports', {
      campaignId: this.campaign.id,
      reports: [ReportType.Campaign],
    });
  }

  get campaignReportLoaded() {
    return this.$store.getters['campaignReports/campaignReportLoaded'](this.campaign.id);
  }

  get campaignReport(): CampaignReport | undefined {
    return this.$store.getters['campaignReports/campaignReport'](this.campaign.id);
  }

  get title() {
    if (this.feedbackEnabled) {
      return 'Song Previews & Feedback';
    }
    return 'Song Previews';
  }

  get promoPageUrl() {
    let { url } = this.campaignReport!.promoPage;
    if (url && this.$route.name) {
      url = `${url}${url.indexOf('?') === -1 ? '?' : '&'}_src=${this.$route.name}`;
    }
    return url;
  }

  selectedSecond: number = -1;

  get plays() {
    if (this.selectedSecond >= 0) {
      return (this.progressNormalized.find((i) => i.at === this.selectedSecond) || { value: 0 }).value;
    }
    return this.audioData.plays || 0;
  }

  get audioData() {
    if (this.campaignReport && this.campaignReport.audio.length > 0) {
      return this.campaignReport.audio[0];
    }
    return {} as ReportAudio;
  }

  get audioTitle() {
    return this.campaign!.ugc!.audio[0].source.name;
  }

  get playsLabel() {
    return this.selectedSecond > 0 ? `${this.selectedSecond}s Plays` : 'Previews';
  }

  get progressNormalized() {
    if (!this.audioData.progress || this.audioData.progress.length === 0) {
      return [];
    }
    const durationSeconds = Math.round(this.audioData.durationMs / 1000);
    const progress = Array(durationSeconds / 3 + 1).fill(0);
    this.audioData.progress.forEach((e) => {
      progress[Math.round(e.durationMs / 1000 / 3)] += e.count;
    });
    return progress.map((cnt: number, idx: number) => ({
      at: Math.max((idx) * 3, 1),
      value: cnt,
    }));
  }

  get feedbackEnabled() {
    return this.audioData.averageFeedback;
  }

  get highestRatingPercent() {
    return Math.max(...this.ratingPercents.map((i) => i.percent));
  }

  get ratingPercents() {
    if (!this.audioData.feedback || this.audioData.feedback.length === 0) {
      return [5, 4, 3, 2, 1].map((rating) => ({ rating, percent: 0 }));
    }
    return this.audioData.feedback.map((i) => ({
      rating: i.rating,
      percent: i.percentage,
    })).sort((l, r) => r.rating - l.rating);
  }

  get averageRating() {
    const rating = this.audioData.averageFeedback || 0;
    return Math.round(rating * 10.0) / 10.0;
  }

  get helpRetailerString() {
    const retailerNames: string[] = [];
    this.campaign.landingPages.forEach((i) => {
      const normalizedDomain = normalizedDomainFromURL(i.url);
      HELP_TEXT_RETAILERS.forEach(([domain, name]) => {
        if (normalizedDomain === domain || normalizedDomain.endsWith(`.${domain}`)) {
          retailerNames.push(name);
        }
      });
    });
    if (retailerNames.length === 1) {
      return retailerNames[0];
    }
    if (retailerNames.length > 1) {
      let retailerString = '';
      sortHelpTextRetailerNames(retailerNames).forEach((i, index) => {
        if (index > 0) {
          retailerString += ', ';
          if (index === retailerNames.length - 1) {
            retailerString += 'or ';
          }
        }
        retailerString += i;
      });
      return retailerString;
    }
    return 'your final destination';
  }

  handleSelectSecond(e: any) {
    this.selectedSecond = e.at;
  }

  handleDeselectSecond() {
    this.selectedSecond = -1;
  }
}
