
























































































































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import abbreviate from 'number-abbreviate';
import { Campaign } from '@/shared/store/campaign';
import {
  CampaignReport,
  CreativeReport,
  ReportType,
} from '@/shared/store/campaignReports';
import {
  campaignAudio,
  hasFacebook,
  hasInstagram,
  hasSocial,
} from '@/shared/lib/campaign';

import InformationPanel from '@/shared/components/campaign/results/InformationPanel.vue';
import { isEnded } from '@/shared/lib/campaign_status';
import { Creative_Network as CreativeNetwork, FacebookPage, LandingPage } from '@/shared/gen/messages.pisa';
import ExampleCreativesModal from '@/shared/components/modals/ExampleCreativesModal.vue';
import CreativePerformance from '@/shared/components/campaign/results/CreativePerformance.vue';

@Component({
  components: {
    CreativePerformance,
    ExampleCreativesModal,
    InformationPanel,
  },
})
export default class Ads extends Vue {
  $refs!: {
    informationPanel: InformationPanel,
    interactiveCreatives: CreativePerformance,
    socialCreatives: CreativePerformance,
  }

  @Getter('campaign/campaignDetails') campaign: Campaign;

  @Getter('campaign/getLandingPages') landingPages: LandingPage[];

  @Getter('campaign/getLandingPageDomains') landingDomains: string[];

  @Getter('campaign/getAdNetworks') getAdNetworks: string[];

  get creativeReportsLoading(): boolean {
    return this.$store.getters['campaignReports/creativeReportsLoading'](this.campaign.id);
  }

  get creativeReportsLoaded(): boolean {
    return this.$store.getters['campaignReports/creativeReportsLoaded'](this.campaign.id);
  }

  @Getter('layout/isMobile') isMobile: boolean;

  @Getter('layout/isTablet') isTablet: boolean;

  @Getter('campaign/getCampaignFacebookPage') campaignFacebookPage: FacebookPage;

  get displayObjectives() {
    let objectives = [
      {
        objective: 'Geo',
        label: 'Local Love',
        icon: [],
      },
    ];
    if (this.hasAudio) {
      objectives = objectives.concat([
        {
          objective: 'Play',
          label: 'Playable',
          icon: [],
        },
        {
          objective: 'Feedback',
          label: 'Feedback',
          icon: [],
        },
      ]);
    }
    objectives.push({
      objective: 'Click',
      label: 'Streaming',
      icon: [],
    });
    return objectives;
  }

  get artistName() {
    if (this.campaign.ugc && this.campaign.ugc.fields.artistName) {
      return this.campaign.ugc.fields.artistName || '';
    }
    return '';
  }

  get releaseName() {
    if (this.campaign.ugc && this.campaign.ugc.fields.releaseName) {
      return this.campaign.ugc.fields.releaseName || '';
    }
    return '';
  }

  get socialPlacements() {
    const placements = [];
    if (this.hasFacebook) {
      placements.push({
        network: 'facebook',
        objective: 'NEWS_FEED',
        label: 'News Feed',
        icon: ['fab', 'facebook'],
        justify: 'left',
      });
    }
    if (this.hasInstagram) {
      placements.push({
        network: 'instagram',
        objective: 'FEED',
        label: 'Feed',
        icon: ['fab', 'instagram'],
        justify: 'center',
      },
      {
        network: 'instagram',
        objective: 'STORY',
        label: 'Story',
        icon: ['fab', 'instagram'],
        justify: 'center',
      });
    }
    return placements;
  }

  get hasSocial() {
    return hasSocial(this.campaign);
  }

  get hasFacebook() {
    return hasFacebook(this.campaign);
  }

  get hasInstagram() {
    return hasInstagram(this.campaign);
  }

  get hasAudio() {
    return !!campaignAudio(this.campaign);
  }

  get hasApple() {
    return this.hasDomain('music.apple.com');
  }

  get hasSpotify() {
    return this.hasDomain('spotify.com');
  }

  get hasDeezer() {
    return this.hasDomain('deezer.com');
  }

  hasDomain(domain: string) {
    const re = new RegExp(`(?:^|\\.)${domain}$`);
    return this.landingDomains.findIndex((i) => i.match(re) !== null) !== -1;
  }

  get retailerNames() {
    if (this.hasApple && this.hasSpotify && this.hasDeezer) {
      return 'Spotify, Apple Music, and Deezer';
    }
    if (this.hasApple && this.hasSpotify) {
      return 'Spotify, and Apple Music';
    }
    if (this.hasApple && this.hasDeezer) {
      return 'Apple Music, and Deezer';
    }
    if (this.hasSpotify && this.hasDeezer) {
      return 'Spotify, and Deezer';
    }
    return '';
  }

  get interactiveSlotName() {
    if (this.hasSocial) {
      return 'interactive';
    }
    return 'default';
  }

  get creativePanes() {
    if (this.hasSocial) {
      return {
        interactive: 'Interactive',
        social: 'Social',
      };
    }
    return {};
  }

  get creativeReports(): CreativeReport[] | undefined {
    return this.$store.getters['campaignReports/creativeReports'](this.campaign.id);
  }

  get creativesForNetwork() {
    return (networkType: string) => this.creativeData.filter((creative) => creative.networkType === networkType);
  }

  get creativeData() {
    if (!this.isUnlocked || !this.creativeReports) {
      return [];
    }
    return this.creativeReports.map((i) => {
      const { impressions, interactions } = i.report;
      const { creative } = i;
      let adNetwork = '';
      let networkType = '';
      let adObjective = '';
      let adPlacement = '';
      let screenshot = '';
      let ad = {};
      networkType = 'Display';
      adNetwork = 'Display';
      if (creative.properties.geo) {
        adPlacement = 'Local Love';
        adObjective = 'Geo';
      } else {
        adPlacement = this.interactivePlacementCopy(creative.properties.objective);
        adObjective = creative.properties.objective;
      }
      if (creative.network === CreativeNetwork.DISPLAY) {
        screenshot = creative.display.screenshotUrl;
      } else if (creative.network === CreativeNetwork.FACEBOOK) {
        const { facebook } = creative;
        networkType = 'Social';
        adNetwork = 'Facebook';
        adPlacement = this.copyStringFormatter(facebook.placement);
        adObjective = facebook.placement;
        screenshot = facebook.imageUrl;
        ad = {
          facebook: {
            primaryText: facebook.primaryText,
            headline: facebook.headline,
            description: facebook.description,
            callToAction: facebook.callToAction,
            caption: facebook.caption,
            imageUrl: facebook.imageUrl,
          },
        };
      } else if (creative.network === CreativeNetwork.INSTAGRAM) {
        const { instagram } = creative;
        networkType = 'Social';
        adNetwork = 'Instagram';
        adPlacement = this.copyStringFormatter(instagram.placement);
        adObjective = instagram.placement;
        screenshot = instagram.imageUrl;
        ad = {
          instagram: {
            primaryText: instagram.primaryText,
            callToAction: instagram.callToAction,
            imageUrl: instagram.imageUrl,
            colorPalette: instagram.colorPalette,
            placement: instagram.placement,
          },
        };
      }
      return {
        id: i.creative.id,
        impressions,
        interactions,
        engagement: impressions > 0 ? (interactions / impressions) * 100 : 0,
        networkType,
        adNetwork,
        placement: adPlacement,
        objective: adObjective,
        screenshot,
        ad,
      };
    });
  }

  mounted() {
    this.$store.dispatch('facebook/loadSyncedPages');
    this.$store.dispatch('campaignReports/getReports', {
      campaignId: this.campaign.id,
      reports: [ReportType.Creative],
    }).then(() => {
      this.checkForPop();
    });
  }

  checkForPop() {
    if (this.$route.query.displayCreative && typeof this.$route.query.displayCreative === 'string') {
      const creativeId = this.$route.query.displayCreative.toString();
      const creativeIndex = this.creativeData.findIndex((c: any) => c.id === creativeId);
      if (creativeIndex !== -1) {
        const creative = this.creativeData[creativeIndex];
        if (creative.networkType === 'Display') {
          this.$refs.informationPanel.setActivePane(this.interactiveSlotName);
          this.$nextTick(() => {
            this.$refs.interactiveCreatives.setCurrentView('list');
            this.$refs.interactiveCreatives.viewCreative(creative);
          });
        } else if (creative.networkType === 'Social') {
          this.$refs.informationPanel.setActivePane('social');
          this.$nextTick(() => {
            this.$refs.socialCreatives.setCurrentView('list');
            this.$refs.socialCreatives.viewCreative(creative);
          });
        }
      }
    }
  }

  interactivePlacementCopy(s: string) {
    switch (s) {
      case 'Click':
        return 'Streaming';
      case 'Play':
        return 'Playable';
      default:
        return s;
    }
  }

  copyStringFormatter(s: string) {
    if (s && s !== '') {
      let retString = s.replace('_', ' ');
      retString = retString.toLowerCase();
      const [firstLetter] = retString.slice(0, 1);
      return `${firstLetter.toUpperCase()}${retString.slice(1)}`;
    }
    return s;
  }

  localeStringFormatter(n: number) {
    return n > 99999 && this.isMobile ? abbreviate(n, 1) : n.toLocaleString();
  }

  engagementRateFormatter(n: number) {
    return `${n.toFixed(2)}%`;
  }

  get isUnlocked() {
    return this.$store.getters['campaignReports/unlocked'](this.campaign.id);
  }

  get isEnded() {
    return isEnded(this.campaign.status!);
  }

  get canSortCreatives() {
    return this.isUnlocked && this.creativeData.length > 0;
  }

  get campaignReport(): CampaignReport | undefined {
    return this.$store.getters['campaignReports/campaignReport'](this.campaign.id);
  }

  get promoPageUrl() {
    let { url } = this.campaignReport!.promoPage;
    if (url && this.$route.name) {
      url = `${url}${url.indexOf('?') === -1 ? '?' : '&'}_src=${this.$route.name}`;
    }
    return url;
  }
}
