



































import { Component, PropSync, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import SortableField from '@/shared/components/campaign/results/SortableField.vue';
import { ImageReport } from '@/shared/store/campaignReports';
import { Campaign } from '@/shared/store/campaign';
import Table from '@/shared/components/campaign/results/Table.vue';
import { getImgixSizeQuerystring } from '@/shared/lib/imgixFunctions';
import { Sort } from '@/shared/components/campaign/results/GridSortControl.vue';

@Component({
  components: {
    Table,
    SortableField,
  },
})
export default class ImageResultsTable extends Vue {
  $refs: {
    [key: string]: any,
  }

  @Getter('layout/isMobile') isMobile: boolean;

  @Getter('campaign/campaignDetails') campaign: Campaign;

  @PropSync('sort')
  sortProp: Sort;

  fields: any = [{
    key: 'image',
    label: '',
    justify: 'left',
    sortable: false,
  }, {
    key: 'theme',
    label: 'Theme',
    justify: 'left',
    sortable: true,
  }];

  updateSort($event: any) {
    const { sortBy, sortDesc } = $event;
    this.sortProp.field = sortBy;
    this.sortProp.desc = sortDesc;
  }

  get isUnlocked() {
    return this.$store.getters['campaignReports/unlocked'](this.campaign.id);
  }

  get imageReports(): ImageReport[] | undefined {
    return this.$store.getters['campaignReports/imageReports'](this.campaign.id);
  }

  get imageData() {
    if (!this.isUnlocked || !this.imageReports) {
      return [];
    }
    return this.imageReports.map((val) => {
      const { impressions, interactions } = val.report;
      const {
        id, type, url, theme,
      } = val;
      return {
        id,
        type,
        url,
        theme: this.userFacingThemeMap[theme] || theme,
        impressions,
        interactions,
        engagement: impressions > 0 ? (interactions / impressions) * 100 : 0,
      };
    });
  }

  get userFacingThemeMap() : { [key: string]: string } {
    return {
      Geo: 'Location Based',
      Genre: 'Genre Specific',
      Fan: 'Fan Focused',
      User: this.userImageCount > 1 ? 'Your Images' : 'Your Image',
    };
  }

  get userImageCount() {
    return this.campaign.ugc!.images.length;
  }

  thumbnail(url: string) {
    return getImgixSizeQuerystring(url, 48, 40);
  }
}
