


































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import AbbreviatedValue from '@/shared/components/common/AbbreviatedValue.vue';
import LabeledValue from '@/shared/components/campaign/results/LabeledValue.vue';

export const IMPRESSIONS = 'impressions';
export const INTERACTIONS = 'interactions';

@Component({
  components: {
    AbbreviatedValue,
    LabeledValue,
  },
})
export default class SocialAdDemographicsChart extends Vue {
  @Prop({ required: true }) mode: 'impressions' | 'interactions';

  @Prop({ required: true }) data: any;

  clientWidth: number = 0;

  created() {
    window.addEventListener('resize', this.handleResize, { passive: true });
  }

  mounted() {
    this.handleResize();
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }

  get abbreviatedValueLength() {
    if (this.clientWidth > 0 && this.clientWidth < 400) {
      return 4;
    }
    return this.$mq === 'xs' ? 10 : 12;
  }

  get IMPRESSIONS() {
    return IMPRESSIONS;
  }

  get INTERACTIONS() {
    return INTERACTIONS;
  }

  get maleTotal() {
    return this.data.reduce((acc: number, i: any) => acc + i.male, 0);
  }

  get femaleTotal() {
    return this.data.reduce((acc: number, i: any) => acc + i.female, 0);
  }

  // unknown?
  get total() {
    return this.maleTotal + this.femaleTotal;
  }

  handleResize() {
    this.clientWidth = this.$el.clientWidth;
  }
}
