





































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import GridSortControl, { Sort } from '@/shared/components/campaign/results/GridSortControl.vue';
import ViewControl from '@/shared/components/campaign/results/ViewControl.vue';

@Component({
  components: { ViewControl, GridSortControl },
})
export default class CreativeControls extends Vue {
  @Getter('layout/isMobile') isMobile: boolean;

  @Getter('layout/isTablet') isTablet: boolean;

  @Prop({ default: true })
  displaySortSelect: boolean;

  @Prop({ default: () => [] })
  displayOptions: string[];

  @Prop({ default: '' })
  campaignFacebookPageId: string;

  @Prop({ default: () => [] })
  selectedDisplayOptions: string[];

  @Prop({ default: 'grid' })
  view: string;

  @Prop({ default: () => [] })
  sortableFields: string[];

  @Prop({ default: '' })
  sortBy: string;

  @Prop({ default: true })
  sortDesc: boolean;

  @Prop({ default: true })
  isUnlocked: boolean;

  @Prop({ default: false })
  isEnded: boolean;

  @Prop({ default: true })
  hasStats: boolean;

  @Watch('sortBy') sortByWatcher() {
    this.gridSortBy.field = this.sortBy;
  }

  @Watch('sortDesc') sortDescWatcher() {
    this.gridSortBy.desc = this.sortDesc;
  }

  gridSortBy: Sort;

  created() {
    this.gridSortBy = { field: this.sortBy, desc: this.sortDesc };
  }

  get filteredDisplayOptions() {
    return this.selectedDisplayOptions;
  }

  set filteredDisplayOptions(value: string[]) {
    this.$emit('filter-change', value);
  }

  get currentView() {
    return this.view;
  }

  set currentView(value: string) {
    this.$emit('view-change', value);
  }

  get facebookAdLibraryLinkSrc() {
    // eslint-disable-next-line max-len
    return `https://www.facebook.com/ads/library/?active_status=all&ad_type=all&country=US&impression_search_field=has_impressions_lifetime&view_all_page_id=${this.campaignFacebookPageId}`;
  }

  get facebookAdLibraryLinkCopy() {
    return 'View on Facebook';
  }
}
