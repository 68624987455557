






















































































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { LandingPage } from '@/shared/gen/messages.pisa';
import { Campaign } from '@/shared/store/campaign';
import { CampaignReport, ReportType } from '@/shared/store/campaignReports';
import AbbreviatedValue from '@/shared/components/common/AbbreviatedValue.vue';
import AbbreviatedLabeledValue from '@/shared/components/campaign/results/AbbreviatedLabeledValue.vue';
import InformationPanel from '@/shared/components/campaign/results/InformationPanel.vue';
import {
  AppleMusic,
  Bandcamp,
  BandLab,
  Deezer,
  Spotify,
  normalizeDomain,
  normalizedDomainFromURL,
} from '@/shared/lib/domains';

const LOGOS = [
  [AppleMusic, 'retailer-apple.svg'],
  [Bandcamp, 'logo-bandcamp.svg'],
  [BandLab, 'retailer-bandlab.svg'],
  [Deezer, 'retailer-deezer.svg'],
  ['soundcloud.com', 'logo-soundcloud.svg'],
  [Spotify, 'retailer-spotify.svg'],
  ['youtube.com', 'logo-youtube.svg'],
];

@Component({
  components: {
    AbbreviatedLabeledValue,
    AbbreviatedValue,
    InformationPanel,
  },
})
export default class LinkClicks extends Vue {
  @Getter('campaign/campaignDetails') campaign: Campaign;

  mounted() {
    this.$store.dispatch('campaignReports/getReports', {
      campaignId: this.campaign.id,
      reports: [ReportType.Campaign],
    });
  }

  get campaignReportLoaded() {
    return this.$store.getters['campaignReports/campaignReportLoaded'](this.campaign.id);
  }

  get campaignReport(): CampaignReport | undefined {
    return this.$store.getters['campaignReports/campaignReport'](this.campaign.id);
  }

  get totalClicks() {
    if (this.campaignReport) {
      return Object.values(this.clicksByRetailer).reduce((acc, i) => acc + i, 0);
    }
    return 0;
  }

  get clicksByRetailer() {
    if (this.campaignReport) {
      const clicksByRetailer: { [key: string]: number } = {};
      this.campaignReport.retailers.forEach((i) => {
        clicksByRetailer[i.retailer] = i.clicks;
      });
      return clicksByRetailer;
    }
    return {};
  }

  get retailerClicks() : { [key: string]: number } {
    if (this.isCustomRetailer) {
      return {};
    }
    const order = ['bandlab', 'spotify', 'apple', 'deezer'];
    return Object.fromEntries(
      Object.entries<number>(
        (this.campaign.landingPages || [])
          .filter((lp: LandingPage) => lp.retailer !== 'other')
          .reduce((acc: { [key: string]: number }, lp: LandingPage): { [key: string]: number } => ({
            ...acc,
            [lp.retailer]: this.clicksByRetailer[lp.retailer] || 0,
          }), {}),
      )
        .sort((a: [string, number], b: [string, number]) => {
          if (b[1] - a[1]) {
            return b[1] - a[1];
          }
          return order.indexOf(a[0]) < order.indexOf(b[0]) ? -1 : 1;
        }),
    );
  }

  get isSingleRetailer() {
    return this.campaign.landingPages && this.campaign.landingPages.length === 1;
  }

  get isCustomRetailer() {
    return this.isSingleRetailer && this.campaign.landingPages![0].retailer === 'other';
  }

  get singleRetailerDisplay() {
    if (!this.isSingleRetailer) {
      return '';
    }
    const { url = '' } = this.campaign.landingPages![0];
    switch (normalizeDomain(url)) {
      case AppleMusic: return 'Apple Music';
      case BandLab: return 'BandLab';
      case Spotify: return 'Spotify';
      case Deezer: return 'Deezer';
      default:
        return url;
    }
  }

  customRetailerLogo(url: string) {
    const domain = this.customRetailerDomain(url);
    const logo = LOGOS.find((pair) => pair[0] === domain);
    if (logo) {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require(`@/assets/common/${logo[1]}`);
    }
    return '';
  }

  customRetailerFavicon(url: string) {
    return `https://s2.googleusercontent.com/s2/favicons?domain_url=${url}&sz=64`;
  }

  customRetailerDomain(url: string) {
    return normalizedDomainFromURL(url);
  }

  retailerLogo(retailer: string) {
    if (retailer === 'other') {
      return '';
    }
    // eslint-disable-next-line global-require,import/no-dynamic-require
    return require(`@/assets/common/retailer-${retailer}.svg`);
  }

  private maxHeightPx: number = 100;

  retailerBarHeight(retailer: string) {
    if (!this.totalClicks || !this.retailerClicks[retailer]) {
      return '5px';
    }
    const h = (this.retailerClicks[retailer] / this.totalClicks) * this.maxHeightPx;
    return `${h}px`;
  }

  retailerHref(retailer: string) {
    const landingPage = (this.campaign.landingPages || []).find((lp: LandingPage) => lp.retailer === retailer);
    if (landingPage) {
      return landingPage.url;
    }
    return '';
  }
}
