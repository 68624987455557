import { render, staticRenderFns } from "./InformationPanel.vue?vue&type=template&id=b0a5d710&scoped=true&"
import script from "./InformationPanel.vue?vue&type=script&lang=ts&"
export * from "./InformationPanel.vue?vue&type=script&lang=ts&"
import style0 from "./InformationPanel.vue?vue&type=style&index=0&id=b0a5d710&lang=scss&scoped=true&"
import style1 from "./InformationPanel.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0a5d710",
  null
  
)

export default component.exports