

























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Creative as CreativeType } from '@/shared/gen/messages.pisa';
import Creative from '@/shared/components/common/Creative.vue';
import LoadingBackground from '@/shared/components/common/LoadingBackground.vue';
import DisabledAdOverlay from '@/shared/components/common/DisabledAdOverlay.vue';

@Component({
  mixins: [],
  components: {
    DisabledAdOverlay,
    Creative,
    LoadingBackground,
  },
  props: {},
  validations: {},
})
export default class StrategyTemplate extends Vue {
  $refs!: {
    strategyCreative: any,
  };

  @Prop({ default: 'StrategyGroup' })
  strategyType: string;

  @Prop({ default: () => {} })
  creative: CreativeType;

  @Prop({ default: '' })
  type: string;

  @Prop({ default: -1 })
  index: number;

  @Prop({ default: false })
  disablePointer: boolean;

  handleAdClick(data: any) {
    this.$emit('click', data);
  }
}
