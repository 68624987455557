


















import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import SortableField from '@/shared/components/campaign/results/SortableField.vue';
import { CopyReport } from '@/shared/store/campaignReports';
import { Campaign } from '@/shared/store/campaign';
import Table from '@/shared/components/campaign/results/Table.vue';

@Component({
  components: {
    Table,
    SortableField,
  },
})
export default class CopyResultsTable extends Vue {
  @Getter('layout/isMobile') isMobile: boolean;

  @Getter('campaign/campaignDetails') campaign: Campaign;

  fields: any = [{
    key: 'copy',
    label: 'Main Message',
    justify: 'left',
    sortable: true,
  }];

  get isUnlocked() {
    return this.$store.getters['campaignReports/unlocked'](this.campaign.id);
  }

  get copyReports(): CopyReport[] | undefined {
    return this.$store.getters['campaignReports/copyReports'](this.campaign.id);
  }

  get copyData() {
    if (!this.isUnlocked || !this.copyReports) {
      return [];
    }
    return this.copyReports.map((val) => {
      const { impressions, interactions } = val.report;
      const { header, subheader } = val;
      return {
        copy: header,
        header,
        subheader,
        impressions,
        interactions,
        engagement: impressions > 0 ? (interactions / impressions) * 100 : 0,
      };
    });
  }
}
