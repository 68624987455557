export const AppleMusic = 'music.apple.com';
export const Bandcamp = 'bandcamp.com';
export const BandLab = 'bandlab.com';
export const Deezer = 'deezer.com';
// export const ReverbNation = 'reverbnation.com';
export const Spotify = 'spotify.com';

const normalizedDomains = [
  AppleMusic,
  Bandcamp,
  BandLab,
  Deezer,
  // ReverbNation,
  Spotify,
];

export function normalizeDomain(domain: string) {
  if (!domain) {
    return domain;
  }
  const knownDomain = normalizedDomains.find((i) => domain.endsWith(`.${i}`));
  if (knownDomain) {
    return knownDomain;
  }
  const domainParts = domain.split('.');
  if (domainParts[0] === 'www') {
    domainParts.shift();
  }
  return domainParts.join('.');
}

export function normalizedDomainFromURL(url: string) {
  const a = document.createElement('a');
  a.href = url;
  return normalizeDomain(a.hostname);
}
