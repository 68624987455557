










import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { hasSocial } from '@/shared/lib/campaign';
import { Campaign } from '@/shared/store/campaign';
import SocialAdDemographics from '@/shared/components/campaign/results/SocialAdDemographics.vue';
import TargetingPerformance from '@/shared/components/campaign/results/TargetingPerformance.vue';

@Component({
  components: {
    SocialAdDemographics,
    TargetingPerformance,
  },
})
export default class Audience extends Vue {
  @Getter('campaign/campaignDetails') campaign: Campaign;

  get hasSocial() {
    return hasSocial(this.campaign);
  }
}
