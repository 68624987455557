


































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { format } from 'date-fns';
import { Campaign } from '@/shared/store/campaign';
import { networks } from '@/shared/lib/budgetOptions';
import { campaignImage, stateText, daysLeftText } from '@/shared/lib/campaign';
import { Status, isEnded } from '@/shared/lib/campaign_status';

@Component
export default class ReleaseDetails extends Vue {
  @Prop({ required: true }) campaign: Campaign;

  get thumbnail() {
    return campaignImage(this.campaign);
  }

  get isEnded() {
    return isEnded(this.campaign.status!);
  }

  get isRunning() {
    return this.campaign.status === Status.Running;
  }

  get startDate() {
    return this.campaign.activatedAt;
  }

  get startDateText() {
    if (!this.startDate) {
      return '';
    }
    if (this.endDate && this.startDate.getFullYear() === this.endDate.getFullYear()) {
      return format(this.startDate, 'MMM d');
    }
    return format(this.startDate, 'MMM d, yyyy');
  }

  get endDate() {
    return this.campaign.endedAt;
  }

  get endDateText() {
    if (!this.endDate) {
      return '';
    }
    return format(this.endDate, 'MMM d, yyyy');
  }

  get stateText() {
    return stateText(this.campaign.status!);
  }

  get stateClass() {
    return this.campaign.status!.toLowerCase();
  }

  get daysLeftText() {
    return daysLeftText(this.campaign);
  }

  get formattedReleaseType() {
    const { releaseType } = this.campaign.ugc!.fields;
    if (releaseType.toUpperCase() === 'EP') {
      return 'EP';
    }
    return releaseType.substr(0, 1).toUpperCase() + releaseType.substr(1).toLowerCase();
  }

  get icons() {
    const icons: { key: string, name: string, icon: string[], enabled: boolean }[] = [];
    networks.forEach((i) => {
      icons.push({
        key: i.key,
        name: i.name,
        icon: i.icon,
        enabled: this.campaign.adNetworks.indexOf(i.key) !== -1,
      });
    });
    return icons;
  }
}
