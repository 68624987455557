



















































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { hasFacebook, hasInstagram } from '@/shared/lib/campaign';
import { Campaign } from '@/shared/store/campaign';
import InformationPanel from '@/shared/components/campaign/results/InformationPanel.vue';
import SocialAdDemographicsChart from '@/shared/components/campaign/results/SocialAdDemographicsChart.vue';
import { DemoReport, ReportType, MinimalReport } from '@/shared/store/campaignReports';

const ageBuckets = [{
  label: '13-17',
  minAge: 13,
  maxAge: 17,
}, {
  label: '18-24',
  minAge: 18,
  maxAge: 24,
}, {
  label: '25-34',
  minAge: 25,
  maxAge: 34,
}, {
  label: '35-44',
  minAge: 35,
  maxAge: 44,
}, {
  label: '45-54',
  minAge: 45,
  maxAge: 54,
}, {
  label: '55-64',
  minAge: 55,
  maxAge: 64,
}, {
  label: '65+',
  minAge: 65,
  maxAge: Infinity,
}];

interface DemoSummary {
  male: {
    [ageRange: string]: MinimalReport
  }
  female: {
    [ageRange: string]: MinimalReport
  }
}

@Component({
  components: {
    InformationPanel,
    SocialAdDemographicsChart,
  },
})
export default class SocialDemographics extends Vue {
  @Getter('campaign/campaignDetails') campaign: Campaign;

  mounted() {
    this.$store.dispatch('campaignReports/getReports', {
      campaignId: this.campaign.id,
      reports: [ReportType.Demo],
    });
  }

  get demoReportsLoading() {
    return this.$store.getters['campaignReports/demoReportsLoading'](this.campaign.id);
  }

  get demoReports(): DemoReport[] | undefined {
    return this.$store.getters['campaignReports/demoReports'](this.campaign.id);
  }

  get hasFacebook() {
    return hasFacebook(this.campaign);
  }

  get hasInstagram() {
    return hasInstagram(this.campaign);
  }

  get releaseGenre() {
    return this.campaign.ugc!.fields.releaseGenre;
  }

  get minAge() {
    if (this.releaseGenre === 'Pop') {
      const maleSummary = this.reportSummary.male['13-17'] || {};
      const femaleSummary = this.reportSummary.female['13-17'] || {};
      if (maleSummary.impressions > 0 || femaleSummary.impressions > 0 || maleSummary.interactions > 0 || femaleSummary.interactions > 0) {
        return 13;
      }
    }
    return 18;
  }

  get maxAge() {
    switch (this.releaseGenre) {
      case 'Trap':
        return 44;
      case 'Christian/Gospel':
      case 'Christian Rock':
      case 'Classical':
      case 'Jazz':
        return 64;
      default:
        return 54;
    }
  }

  get ageBuckets() {
    return ageBuckets.filter((i) => {
      if (this.maxAge < i.minAge || this.minAge > i.maxAge) {
        return false;
      }
      return true;
    });
  }

  get reportSummary(): DemoSummary {
    const demoReports = this.demoReports || [];
    const summary: DemoSummary = {
      male: {},
      female: {},
    };
    for (let i = 0; i < demoReports.length; i += 1 /* wtf lint? no ++? */) {
      const report = demoReports[i];
      switch (report.gender) {
        case 'male':
          summary.male[report.ageRange] = report.report;
          break;
        case 'female':
          summary.female[report.ageRange] = report.report;
          break;
        case 'unknown':
          break;
        default:
          break;
      }
    }
    return summary;
  }

  normalizeData(metric: 'impressions' | 'interactions') {
    const summary = this.reportSummary;
    return this.ageBuckets.map((i) => ({
      label: i.label,
      male: (summary.male[i.label] || {})[metric] || 0,
      female: (summary.female[i.label] || {})[metric] || 0,
    }));
  }

  get impressionsData() {
    return this.normalizeData('impressions');
  }

  get interactionsData() {
    return this.normalizeData('interactions');
  }
}
