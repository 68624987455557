













































































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import InformationPanel from '@/shared/components/campaign/results/InformationPanel.vue';
import { hasFacebook, hasInstagram, hasInstagramAccount } from '@/shared/lib/campaign';
import { Campaign } from '@/shared/store/campaign';
import SocialProfileIcon from '@/shared/components/common/SocialProfileIcon.vue';
import LabeledValue from '@/shared/components/campaign/results/LabeledValue.vue';
import AbbreviatedLabeledValue
  from '@/shared/components/campaign/results/AbbreviatedLabeledValue.vue';
import { CampaignReport, ReportType } from '@/shared/store/campaignReports';

@Component({
  components: {
    AbbreviatedLabeledValue,
    LabeledValue,
    InformationPanel,
    SocialProfileIcon,
  },
})
export default class SocialActivity extends Vue {
  @Getter('campaign/campaignDetails') campaign: Campaign;

  mounted() {
    this.$store.dispatch('campaignReports/getReports', {
      campaignId: this.campaign.id,
      reports: [ReportType.Campaign],
    });
  }

  get campaignReportLoaded() {
    return this.$store.getters['campaignReports/campaignReportLoaded'](this.campaign.id);
  }

  get campaignReport(): CampaignReport | undefined {
    return this.$store.getters['campaignReports/campaignReport'](this.campaign.id);
  }

  get socialActivity() {
    return this.campaignReport!.socialActivity;
  }

  get helpTextNetworks() {
    if (this.hasFacebook && this.hasInstagram) {
      return 'Facebook and Instagram';
    }
    if (this.hasInstagram) {
      return 'Instagram';
    }
    return 'Facebook';
  }

  get hasFacebook() {
    return hasFacebook(this.campaign);
  }

  get hasInstagram() {
    return hasInstagram(this.campaign);
  }

  get hasInstagramAccount() {
    return hasInstagramAccount(this.campaign);
  }

  get promoPageUrl() {
    let { url } = this.campaignReport!.promoPage;
    if (url && this.$route.name) {
      url = `${url}${url.indexOf('?') === -1 ? '?' : '&'}_src=${this.$route.name}`;
    }
    return url;
  }
}
