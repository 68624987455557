



































































import {
  Component, Inject, Vue, Watch,
} from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import TabBarLabel from '@/shared/components/common/tab-bar/TabBarLabel.vue';
import Activity from '@/shared/components/campaign/results/Activity.vue';
import Audience from '@/shared/components/campaign/results/Audience.vue';
import Assets from '@/shared/components/campaign/results/Assets.vue';
import Ads from '@/shared/components/campaign/results/Ads.vue';
import uuid from '@/shared/lib/uuid';

enum Tabs {
  TabActivity = 'activity',
  TabAudience = 'audience',
  TabAds = 'ads',
  TabAssets = 'assets',
}

const orderedTabs = Object.values(Tabs);

@Component({
  components: {
    TabBarLabel,
    Activity,
    Audience,
    Ads,
    Assets,
  },
})
export default class PageBody extends Vue {
  $refs!: {
    pageBody: HTMLElement,
  }

  @Inject() readonly scrollToTop: Function;

  @Getter('layout/isTouch') isTouch: boolean;

  uniqueId: string = '';

  tabIndex: number = 0;

  @Watch('tabIndex') tabIndexWatcher() {
    this.$root.$emit('InformationPanel::navigated');
    this.scrollToTop();
    // window.location.hash = orderedTabs[this.tabIndex];
  }

  exposeTabs: boolean = false;

  scrollDist: number = 0;

  scrollTrigger: number = 0;

  mounted() {
    window.addEventListener('scroll', this.handleScroll, { passive: true });
    this.$refs.pageBody.addEventListener('transitionend', this.handleTransitionEnd, { passive: true });
  }

  created() {
    this.uniqueId = uuid();
    let targetTabIndex = orderedTabs.indexOf(this.$route.hash.slice(1) as Tabs);
    if (targetTabIndex === -1 && this.$route.query.displayCreative) {
      targetTabIndex = orderedTabs.indexOf(Tabs.TabAds);
    }
    if (targetTabIndex === -1 && this.$route.query.displayAsset) {
      targetTabIndex = orderedTabs.indexOf(Tabs.TabAssets);
    }
    if (targetTabIndex === -1) {
      targetTabIndex = 0;
    }
    this.tabIndex = targetTabIndex;
  }

  beforeDestroy() {
    this.$refs.pageBody.removeEventListener('transitionend', this.handleTransitionEnd);
  }

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  hidingTabs: boolean = false;

  forbidTransition: boolean = false;

  handleScroll() {
    const dist = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
    const overscrolled = dist + document.documentElement.offsetHeight + 100 >= document.documentElement.scrollHeight;
    if (dist === 0 || (this.isTouch && dist < 90)) {
      // at the top of the page, so ensure no fixed tabs (and no animation)
      if (this.exposeTabs) {
        this.forbidTransition = true;
        this.exposeTabs = false;
        setTimeout(() => {
          this.forbidTransition = false;
        }, 250);
      }
      this.scrollTrigger = 0;
    } else if (dist < this.scrollDist && !overscrolled) {
      // scrolling up, expose fixed tabs if far enough down the page and past buffer pixels
      const tabHeight = 68;
      this.exposeTabs = this.exposeTabs || (dist > 450 && dist < this.scrollTrigger - tabHeight);
      if (this.exposeTabs) {
        this.scrollTrigger = dist + tabHeight;
      }
    } else if (this.exposeTabs && dist > this.scrollTrigger) {
      // scrolling down and past buffer pixels, hide
      this.hidingTabs = true;
    } else {
      this.scrollTrigger = Math.max(this.scrollTrigger, this.scrollDist);
    }
    this.scrollDist = dist;
  }

  handleTransitionEnd(evt: TransitionEvent) {
    if ((evt as TransitionEvent).propertyName === 'top'
      && ((evt.target as HTMLElement).closest('.page-body') || { id: undefined }).id === this.uniqueId
      && this.hidingTabs) {
      this.exposeTabs = false;
      this.hidingTabs = false;
    }
  }
}
