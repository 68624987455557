// Take an input array of numbers, and returns the rounded percentages.
export function roundPercentages(input: number[], places: number = 0): number[] {
  const normalizedInput = input.map((i) => i || 0);
  const total = normalizedInput.reduce((acc, i) => acc + i, 0);
  if (total === 0) {
    return normalizedInput;
  }
  const truncateAfter = 10 ** places;
  const percentages = normalizedInput.map((i) => 100 * truncateAfter * (i / total));
  let toAllocate = (100 * truncateAfter) - percentages.reduce((acc, i) => acc + Math.floor(i), 0);
  if (toAllocate > 0) {
    // Allocate extra percentages...
    const allocationOrder = percentages.map((i, index) => [index, i, i - Math.floor(i)])
      .sort((a, b) => {
        // Allocate to largest decimal value first...
        if (a[2] < b[2]) {
          return 1;
        }
        if (a[2] > b[2]) {
          return -1;
        }
        // If decimal value is the same, allocate to largest value first...
        if (a[1] < b[1]) {
          return 1;
        }
        if (a[1] > b[1]) {
          return -1;
        }
        // They are the same...
        return 0;
      }).map((i) => i[0]);
    while (toAllocate > 0) {
      for (let i = 0; toAllocate > 0 && i < allocationOrder.length; i += 1) {
        percentages[allocationOrder[i]] += 1;
        toAllocate -= 1;
      }
    }
  }
  return percentages.map((i) => Math.floor(i) / truncateAfter);
}

export function ordinal(n: number): string {
  const j = n % 10;
  const k = n % 100;
  if (j === 1 && k !== 11) {
    return `${n}st`;
  }
  if (j === 2 && k !== 12) {
    return `${n}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${n}rd`;
  }
  return `${n}th`;
}

export default {
  roundPercentages,
  ordinal,
};
