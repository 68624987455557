










































import {
  Component, Prop, Provide, Vue, Watch,
} from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import BackButton from '@/shared/components/site/BackButton.vue';
import LogoLink from '@/shared/components/site/LogoLink.vue';

const trxToBriefPixels = 90;
const trxHalf = trxToBriefPixels / 2;

@Component({
  components: {
    LogoLink,
    BackButton,
  },
})
export default class LeafLayout extends Vue {
  $refs!: {
    layoutHead: HTMLElement,
    backButton: HTMLElement,
    headerFull: HTMLElement,
    headerBrief: HTMLElement,
  }

  @Prop({ }) onBack: any;

  created() {
    if (window.scrollTo) {
      window.scrollTo(0, 0);
    }
  }

  mounted() {
    window.addEventListener('scroll', this.handleScroll, { passive: true });
  }

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll, true);
  }

  @Getter('layout/isTouch') isTouch: boolean;

  @Watch('isTouch') isTouchWatcher(newVal: boolean) {
    if (newVal) {
      this.handleScroll();
    } else {
      this.$refs.headerFull.style.opacity = '1';
      this.$refs.layoutHead.style.top = '0';
      this.$refs.backButton.style.bottom = 'unset';
      this.$refs.headerBrief.style.opacity = '0';
    }
  }

  currScrollDist() {
    return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
  }

  handleScroll() {
    if (!this.isTouch) {
      return;
    }
    const {
      layoutHead, backButton, headerFull, headerBrief,
    } = this.$refs;
    if (!(layoutHead && backButton && headerFull && headerBrief)) {
      return;
    }
    const dist = this.currScrollDist();
    if (document.documentElement.scrollHeight - document.documentElement.offsetHeight < trxToBriefPixels) {
      return;
    }
    if (dist <= trxToBriefPixels) {
      const nonNegative = Math.max(0, dist);
      headerFull.style.opacity = `${(trxToBriefPixels - nonNegative) / trxToBriefPixels}`;
      layoutHead.style.top = `${-nonNegative}px`;
      backButton.style.bottom = `${-nonNegative}px`;
      if (dist >= trxHalf) {
        headerBrief.style.bottom = `${trxToBriefPixels - (trxToBriefPixels * (dist - trxHalf)) / trxHalf}px`;
        headerBrief.style.opacity = `${(dist - trxHalf) / trxHalf}`;
      } else {
        headerBrief.style.bottom = '90px';
        headerBrief.style.opacity = '0';
      }
    } else {
      headerFull.style.opacity = '0';
      layoutHead.style.top = '-90px';
      backButton.style.bottom = '-90px';
      headerBrief.style.bottom = '0';
      headerBrief.style.opacity = '1';
    }
  }

  scrollToTop() {
    const dist = this.currScrollDist();
    if (this.isTouch && dist > trxToBriefPixels) {
      this.$nextTick(() => {
        window.scrollTo(0, trxToBriefPixels);
      });
    } else {
      window.scrollTo(0, 0);
    }
  }

  @Provide('scrollToTop') scrollToTopInjection: Function = this.scrollToTop;
}
