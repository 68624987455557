
























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { isEnded } from '@/shared/lib/campaign_status';
import { Campaign } from '@/shared/store/campaign';
import { DomainReport } from '@/shared/store/campaignReports';
import Table from '@/shared/components/campaign/results/Table.vue';
import TableNoResults from '@/shared/components/campaign/results/TableNoResults.vue';

@Component({
  components: {
    TableNoResults,
    Table,
  },
})
export default class DomainPerformance extends Vue {
  @Prop({ required: true })
  campaign: Campaign;

  get reports(): DomainReport[] | undefined {
    return this.$store.getters['campaignReports/domainReports'](this.campaign.id);
  }

  get isUnlocked() {
    return this.$store.getters['campaignReports/unlocked'](this.campaign.id);
  }

  get canSort() {
    return this.isUnlocked && this.items.length > 1;
  }

  get fields() {
    return [{
      key: 'domain',
      justify: 'left',
      sortable: this.canSort,
      tdClass: 'domain',
    }];
  }

  get items() {
    if (!this.isUnlocked || !this.reports) {
      return [];
    }
    return this.reports.map((i) => {
      const { domain, retargeted = false } = i;
      const { impressions, interactions } = i.report;
      return {
        domain: (!domain || domain === '-') ? 'Unknown' : domain,
        retargeted: domain !== '-' && retargeted,
        impressions,
        interactions,
        engagement: impressions > 0 ? (interactions / impressions) * 100 : 0,
      };
    });
  }

  get noResultsCopy() {
    if (isEnded(this.campaign.status!)) {
      return 'No domain performance stats to display.';
    }
    return 'No domain performance stats to display yet.';
  }

  domainFavicon(url: string) {
    return `https://s2.googleusercontent.com/s2/favicons?domain_url=${url}&sz=32`;
  }
}
