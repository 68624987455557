







































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import abbreviate from 'number-abbreviate';
import { FacebookPage, LandingPage } from '@/shared/gen/messages.pisa';
import LoadingBackground from '@/shared/components/common/LoadingBackground.vue';
import StrategyTemplate from '@/shared/components/builder/StrategyTemplate.vue';
import FacebookTemplate from '@/shared/components/common/FacebookTemplate.vue';
import InstagramTemplate from '@/shared/components/common/InstagramTemplate.vue';
import SimpleModal from '@/shared/components/modals/SimpleModal.vue';

@Component({
  components: {
    FacebookTemplate,
    InstagramTemplate,
    LoadingBackground,
    SimpleModal,
    StrategyTemplate,
  },
})
export default class ExampleCreativesModal extends Vue {
  $refs!: {
    modal: SimpleModal,
  };

  @Prop({ default: () => {} })
  selectedCreative: any;

  @Prop({ default: () => {} })
  landingPages: LandingPage[];

  @Prop({ default: () => {} })
  syncedPage: FacebookPage;

  @Prop({ default: () => {} })
  stats: any;

  @Prop({ default: false })
  isEnded: boolean;

  @Getter('layout/isMobile') isMobile: boolean;

  get ad() {
    return this.selectedCreative;
  }

  get adType() {
    if (this.ad.image) {
      return 'image';
    }
    if (this.ad.display) {
      return 'interactive';
    }
    if (this.ad.facebook) {
      return 'facebook';
    }
    if (this.ad.instagram) {
      return 'instagram';
    }
    return '';
  }

  show() {
    this.$refs.modal.show();
  }

  localeStringFormatter(n: number) {
    return n > 99999 && this.isMobile ? abbreviate(n, 1) : n.toLocaleString();
  }

  engagementRateFormatter(n: number) {
    return `${n.toFixed(2)}%`;
  }

  socialAdClicked() {
    if (this.isEnded) {
      // eslint-disable-next-line no-alert
      window.alert('Normally clicking here would allow fans to find your release, but this is just the demo version of the ad we ran for you.');
    } else {
      // eslint-disable-next-line no-alert
      window.alert('Normally clicking here would allow fans to find your release, but this is just the '
        + 'demo version of the ad we\'re running for you. To see the active version of this ad go to '
        + 'your page\'s ad library on Facebook.');
    }
  }

  interactiveAdClicked(data: any) {
    if (data.retailer) {
      const lpIndex = this.landingPages.findIndex((lp) => lp.retailer === data.retailer);
      const lp = this.landingPages[lpIndex];
      window.open(lp.url);
      return;
    }
    if (this.isEnded) {
      // eslint-disable-next-line no-alert
      window.alert('Normally clicking here would take fans to your release, but this is just the demo version of the ad we ran for you.');
    } else {
      // eslint-disable-next-line no-alert
      window.alert('Normally clicking here would take fans to your release, but this is just the '
        + 'demo version of the ad we\'re running for you.');
    }
  }

  retailerMapping(retailer: string, creativeId: string): string {
    switch (retailer) {
      case 'apple':
        return 'Apple Music';
      case 'google':
        return 'Google Play';
      case 'spotify':
        return 'Spotify';
      case 'deezer':
        return 'Deezer';
      case 'bandlab':
        return 'BandLab';
      default:
        this.$honeybadger.notify('Unknown retailer', 'RetailerMappingError', {
          context: {
            creativeId,
            retailer,
          },
        });
        return '';
    }
  }
}
