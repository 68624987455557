
































































import { Component, Prop, Vue } from 'vue-property-decorator';
import CountryFlag from 'vue-country-flag';
import { isEnded } from '@/shared/lib/campaign_status';
import { codeForCountryName } from '@/shared/lib/countries';
import { Campaign } from '@/shared/store/campaign';
import { CountryReport, ReportType } from '@/shared/store/campaignReports';
import LocationRegionPerformance from '@/shared/components/campaign/results/LocationRegionPerformance.vue';
import Table from '@/shared/components/campaign/results/Table.vue';
import TableNoResults from '@/shared/components/campaign/results/TableNoResults.vue';

@Component({
  components: {
    CountryFlag,
    LocationRegionPerformance,
    TableNoResults,
    Table,
  },
})
export default class LocationPerformance extends Vue {
  $refs!: {
    countryTable: Table & { sortBy: string, sortDesc: boolean },
  }

  @Prop({ required: true })
  campaign: Campaign;

  expandedRows: { [key: string]: boolean } = { };

  get reports(): CountryReport[] | undefined {
    return this.$store.getters['campaignReports/countryReports'](this.campaign.id);
  }

  get isUnlocked() {
    return this.$store.getters['campaignReports/unlocked'](this.campaign.id);
  }

  get canSort() {
    return this.isUnlocked && this.items.length > 1;
  }

  get fields() {
    return [{
      key: 'country',
      justify: 'left',
      sortable: this.canSort,
      thClass: 'country',
      tdClass: 'country',
    }];
  }

  get items() {
    if (!this.isUnlocked || !this.reports) {
      return [];
    }
    return this.reports.map((i) => {
      const { country, hasRegions = false } = i;
      const { impressions, interactions } = i.report;
      return {
        country: (!country || country === '-') ? 'Unknown' : country,
        countryCode: codeForCountryName(country), // TODO: Replace with i.countryCode... See also toggleExpansion and LocationRegionPerformance component!!!
        impressions,
        interactions,
        engagement: impressions > 0 ? (interactions / impressions) * 100 : 0,
        hasRegions,
        // eslint-disable-next-line no-underscore-dangle
        // TODO: Replace with i.countryCode... See also toggleExpansion and LocationRegionPerformance component!!!
        _showDetails: this.expandedRows[i.country] || false,
      };
    });
  }

  toggleExpansion(row: any) {
    if (!row.item.hasRegions) {
      return;
    }
    const { country } = row.item; // TODO: FIXME: Use item.countryCode!!!  See also items and LocationRegionPerformance component!!!
    if (this.expandedRows[country]) {
      this.$delete(this.expandedRows, country);
    } else {
      this.$store.dispatch('campaignReports/getReports', {
        campaignId: this.campaign.id,
        reports: [ReportType.Region],
        countryCodes: [country],
      }).catch(() => {
        this.$set(this.expandedRows, country, false);
      });
      this.$set(this.expandedRows, country, true);
    }
  }

  get noResultsCopy() {
    if (isEnded(this.campaign.status!)) {
      return 'No locations performance stats to display.';
    }
    return 'No locations performance stats to display yet.';
  }

  rowClass(item: any, type: string) {
    // eslint-disable-next-line no-underscore-dangle
    if (item && type === 'row' && item._showDetails) {
      return 'expanded';
    }
    return null;
  }

  get regionSortBy() {
    const { sortBy } = this.$refs.countryTable;
    return sortBy === 'country' ? 'region' : sortBy;
  }

  get regionSortDesc() {
    return this.regionSortBy === 'region' ? false : this.$refs.countryTable.sortDesc;
  }
}
