import { render, staticRenderFns } from "./TabBarLabel.vue?vue&type=template&id=72ab74ff&scoped=true&"
import script from "./TabBarLabel.vue?vue&type=script&lang=ts&"
export * from "./TabBarLabel.vue?vue&type=script&lang=ts&"
import style0 from "./TabBarLabel.vue?vue&type=style&index=0&id=72ab74ff&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72ab74ff",
  null
  
)

export default component.exports