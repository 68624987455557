



























import { Component, Prop, Vue } from 'vue-property-decorator';
import FacebookNewsFeedCreative from '@/shared/components/common/FacebookNewsFeedCreative.vue';
import LoadingBackground from '@/shared/components/common/LoadingBackground.vue';
import * as imgixFunctions from '@/shared/lib/imgixFunctions';
import { FacebookPage } from '@/shared/gen/messages.pisa';
import DisabledAdOverlay from '@/shared/components/common/DisabledAdOverlay.vue';

@Component({
  mixins: [],
  components: {
    DisabledAdOverlay,
    FacebookNewsFeedCreative,
    LoadingBackground,
  },
  props: {},
  validations: {},
})
export default class FacebookTemplate extends Vue {
  @Prop({ required: true })
  creative: any;

  @Prop({ default: false })
  disablePointer: boolean;

  @Prop({ default: false })
  disabled: boolean;

  @Prop({ default: () => {} })
  syncedPage: FacebookPage;

  get primaryText() {
    return this.creative.facebook.primaryText;
  }

  get headline() {
    return this.creative.facebook.headline;
  }

  get callToAction() {
    return this.creative.facebook.callToAction;
  }

  get imageUrl() {
    return imgixFunctions.getImgixSizeQuerystring(this.creative.facebook.imageUrl, 300, 300);
  }

  get caption() {
    return this.creative.facebook.caption;
  }

  get description() {
    return this.creative.facebook.description;
  }

  get userDisabled() {
    if (this.creative.customization) {
      return !this.creative.customization.enabled;
    }
    return false;
  }

  handleAdClick() {
    this.$emit('click');
  }
}
