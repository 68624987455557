













import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class LabeledValue extends Vue {
  @Prop({ required: true })
  label: string;

  @Prop({ required: true })
  value: number | string;

  @Prop()
  valueTitle: number | string;

  @Prop({ default: '-' })
  zeroValue: string;

  get defaultValue() {
    const v = this.value;
    if (v && v.toLocaleString) {
      return v.toLocaleString();
    }
    return v || this.zeroValue;
  }

  get defaultValueTitle() {
    const v = this.value;
    if (v) {
      return v.toLocaleString ? v.toLocaleString() : v;
    }
    return undefined;
  }
}
