




























































































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Campaign } from '@/shared/store/campaign';
import { ReportType } from '@/shared/store/campaignReports';
import DomainPerformance from '@/shared/components/campaign/results/DomainPerformance.vue';
import InformationPanel from '@/shared/components/campaign/results/InformationPanel.vue';
import LocationPerformance from '@/shared/components/campaign/results/LocationPerformance.vue';

// First submission date where we are guaranteed to have location data...
const SupportsLocationSubmissionDate = new Date('2020-04-03');

@Component({
  components: {
    DomainPerformance,
    InformationPanel,
    LocationPerformance,
  },
})
export default class TargetingPerformance extends Vue {
  @Getter('campaign/campaignDetails') campaign: Campaign;

  mounted() {
    const reports = [ReportType.Domain];
    if (this.supportsLocation) {
      reports.push(ReportType.Country);
    }
    this.$store.dispatch('campaignReports/getReports', {
      campaignId: this.campaign.id,
      reports,
    });
  }

  get supportsLocation() { // Shouldn't this just see if any location data comes back and adjust the UI accordingly?
    return this.campaign.submittedAt && this.campaign.submittedAt >= SupportsLocationSubmissionDate;
  }

  get domainReportsLoading() { // TODO: Error state if we are not loading and not loaded?
    return this.$store.getters['campaignReports/domainReportsLoading'](this.campaign.id) || !this.domainReportsLoaded;
  }

  get domainReportsLoaded() {
    return this.$store.getters['campaignReports/domainReportsLoaded'](this.campaign.id);
  }

  get countryReportsLoading() { // TODO: Error state if we are not loading and not loaded?
    if (!this.supportsLocation) {
      return false;
    }
    return this.$store.getters['campaignReports/countryReportsLoading'](this.campaign.id) || !this.countryReportsLoaded;
  }

  get countryReportsLoaded() {
    return this.$store.getters['campaignReports/countryReportsLoaded'](this.campaign.id);
  }

  get hideEngagementText() {
    return this.$mq === 'xs' || this.$mq === 'sm';
  }

  get panes() {
    if (this.supportsLocation) {
      return {
        locations: 'Locations',
        domains: 'Domains',
      };
    }
    return {};
  }
}
