



















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TabBarLabel extends Vue {
  @Prop({ required: true }) name: string;

  @Prop({ default: () => [] }) icon: string[];

  @Prop({ default: false }) active: boolean;
}
