













































import {
  Component, Prop, PropSync, Vue,
} from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import SortableField from '@/shared/components/campaign/results/SortableField.vue';
import * as imgixFunctions from '@/shared/lib/imgixFunctions';
import Table from '@/shared/components/campaign/results/Table.vue';
import { Sort } from '@/shared/components/campaign/results/GridSortControl.vue';

@Component({
  components: {
    Table,
    SortableField,
  },
})
export default class CreativeListView extends Vue {
  @Getter('layout/isMobile') isMobile: boolean;

  @Getter('layout/isTablet') isTablet: boolean;

  @Prop({ default: () => [] })
  creatives: any[];

  @Prop({ default: () => [] })
  filterFields: any[];

  @PropSync('sort')
  sortProp: Sort;

  sortChanged($event: any) {
    const { sortBy, sortDesc } = $event;
    this.$emit('sort-changed', { field: sortBy, desc: sortDesc });
  }

  creativeFields: any[] = [
    {
      key: 'screenshot',
      label: '',
      sortable: false,
      canSort: false,
      collapseLabel: true,
      justify: 'center',
    },
    {
      key: 'placement',
      label: 'Ad Type',
      sortable: true,
      collapseLabel: false,
      justify: 'left',
    },
  ];

  rowClicked(item: any) {
    this.$emit('row-clicked', item);
  }

  getThumbnailUrl(imageUrl: string) {
    return imgixFunctions.getImgixSizeQuerystring(imageUrl, 90, 90);
  }

  get tablePlacementIcon() {
    return (placementName: string) => {
      const idx = this.filterFields.findIndex((field) => field.label.toLowerCase() === placementName.toLowerCase());
      if (idx !== -1 && this.filterFields) {
        return this.filterFields[idx].icon;
      }
      return [];
    };
  }
}
