























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Campaign } from '@/shared/store/campaign';
import { isEnded } from '@/shared/lib/campaign_status';

@Component
export default class ReleaseBrief extends Vue {
  @Prop({ required: true }) campaign: Campaign;

  get isEnded() {
    return isEnded(this.campaign.status!);
  }

  get stateClass() {
    return this.campaign.status!.toLowerCase();
  }

  get formattedReleaseType() {
    const { releaseType } = this.campaign.ugc!.fields;
    if (releaseType.toUpperCase() === 'EP') {
      return 'EP';
    }
    return releaseType.substr(0, 1).toUpperCase() + releaseType.substr(1).toLowerCase();
  }
}
