







































































































































import { Component, Vue } from 'vue-property-decorator';
import {
  addDays, format, startOfDay, isBefore,
} from 'date-fns';
import { Getter } from 'vuex-class';
import { hasSocial } from '@/shared/lib/campaign';
import { isEnded, isInReview } from '@/shared/lib/campaign_status';
import { Campaign } from '@/shared/store/campaign';
import { CampaignReport, DailyReport, ReportType } from '@/shared/store/campaignReports';
import InformationPanel from '@/shared/components/campaign/results/InformationPanel.vue';
import LineChart from '@/shared/components/campaign/results/LineChart.vue';
import LinkClicks from '@/shared/components/campaign/results/LinkClicks.vue';
import SocialActions from '@/shared/components/campaign/results/SocialActions.vue';
import SongPreviewsAndFeedback from '@/shared/components/campaign/results/SongPreviewsAndFeedback.vue';

@Component({
  components: {
    InformationPanel,
    LinkClicks,
    LineChart,
    SocialActions,
    SongPreviewsAndFeedback,
  },
})
export default class Activity extends Vue {
  @Getter('campaign/campaignDetails') campaign: Campaign;

  created() {
    this.$store.dispatch('campaignReports/getReports', {
      campaignId: this.campaign.id,
      reports: [ReportType.Campaign, ReportType.Daily],
    });
  }

  get dailyReports(): DailyReport[] | undefined {
    return this.$store.getters['campaignReports/dailyReports'](this.campaign.id);
  }

  get dailyReportsLoaded() {
    return this.$store.getters['campaignReports/dailyReportsLoaded'](this.campaign.id);
  }

  selectedDate: Date | null = null;

  get isInReview() {
    return isInReview(this.campaign.status!);
  }

  get isEnded() {
    return isEnded(this.campaign.status!);
  }

  get isSingleRetailer() {
    return this.campaign.landingPages && this.campaign.landingPages.length === 1;
  }

  get hasSocial() {
    return hasSocial(this.campaign);
  }

  get hasAudio() {
    return this.campaign!.ugc!.audio.length;
  }

  get startDate() {
    return this.campaign.activatedAt || new Date();
  }

  get endDate() {
    return addDays(this.startDate, this.campaign.durationDays);
  }

  get temporalLabel() {
    if (this.selectedDate) {
      return format(this.selectedDate, 'MMMM d, yyyy');
    }
    return 'Total';
  }

  get impressions() {
    if (this.selectedDate) {
      const timestamp = this.selectedDate!.getTime();
      return (this.impressionData.find((i) => timestamp === i.date.getTime()) || { value: 0 }).value;
    }
    return this.totalImpressions;
  }

  get interactions() {
    if (this.selectedDate) {
      const timestamp = this.selectedDate!.getTime();
      return (this.interactionData.find((i) => timestamp === i.date.getTime()) || { value: 0 }).value;
    }
    return this.totalInteractions;
  }

  get totalImpressions() {
    return this.impressionData.reduce((acc: number, curr: { value: number }) => acc + curr.value, 0);
  }

  get totalInteractions() {
    return this.interactionData.reduce((acc: number, curr: { value: number }) => acc + curr.value, 0);
  }

  get impressionData() {
    return this.normalizePerformanceData('impressions');
  }

  get interactionData() {
    return this.normalizePerformanceData('interactions');
  }

  private normalizePerformanceData(measurement: 'impressions' | 'interactions') {
    if (!this.dailyReports) {
      return [];
    }
    const startDate = startOfDay(this.startDate);
    const fmt = (d: Date) => d.toJSON().slice(0, 10); // UTC Date...
    /* eslint-disable no-param-reassign */
    const data: { [key: string]: number } = this.dailyReports.reduce((acc: { [key: string]: number }, curr) => {
      const { date } = curr;
      const value = curr.report[measurement];
      const formattedDate = fmt(date);
      const tzOffset = date.getTimezoneOffset();
      if (tzOffset === 0 || isBefore(date, startDate)) {
        acc[formattedDate] = (acc[formattedDate] || 0) + value;
      } else {
        const dayFraction = Math.abs(tzOffset / 1440); // 1440 == Minutes Per Day (24 * 60)
        const valFraction = Math.round(value * dayFraction);
        acc[formattedDate] = (acc[formattedDate] || 0) + value - valFraction;
        if (valFraction) {
          if (tzOffset > 0) {
            const adjustedDate = new Date(Math.max(startDate.getTime(), addDays(date, -1).getTime()));
            acc[fmt(adjustedDate)] = (acc[fmt(adjustedDate)] || 0) + valFraction;
          } else {
            const adjustedDate = new Date(Math.min(new Date().getTime(), addDays(date, 1).getTime()));
            acc[fmt(adjustedDate)] = (acc[fmt(adjustedDate)] || 0) + valFraction;
          }
        }
      }
      return acc;
    }, {});
    /* eslint-enable no-param-reassign */
    return Object.entries(data).map(([dateString, value]) => {
      const d = new Date(dateString);
      return {
        date: new Date(d.getTime() + (d.getTimezoneOffset() * 60 * 1000)),
        value,
      };
    });
  }

  deselectDate() {
    this.selectedDate = null;
  }

  get campaignReport(): CampaignReport | undefined {
    return this.$store.getters['campaignReports/campaignReport'](this.campaign.id);
  }

  get promoPageUrl() {
    let { url } = this.campaignReport!.promoPage;
    if (url && this.$route.name) {
      url = `${url}${url.indexOf('?') === -1 ? '?' : '&'}_src=${this.$route.name}`;
    }
    return url;
  }
}
