






















import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { Campaign } from '@/shared/store/campaign';
import { RegionReport } from '@/shared/store/campaignReports';
import Table from '@/shared/components/campaign/results/Table.vue';
import LoadingIndicator from '@/shared/components/common/LoadingIndicator.vue';

@Component({
  components: {
    LoadingIndicator,
    Table,
  },
})
export default class LocationRegionPerformance extends Vue {
  $refs!: {
    regionTable: Table & { sortBy: string, sortDesc: boolean },
  }

  @Prop({ required: true })
  campaign: Campaign;

  @Prop({ required: true })
  countryCode: string;

  @Prop({ default: 'region' })
  sortBy: string;

  @Prop({ default: false })
  sortDesc: boolean;

  mounted() {
    this.setRegionTableSort();
  }

  get reports(): RegionReport[] | undefined {
    return this.$store.getters['campaignReports/regionReports'](this.campaign.id, this.countryCode);
  }

  get fields() {
    return [{
      key: 'region',
      thClass: 'region',
      tdClass: 'region',
    }];
  }

  get items() {
    if (!this.reports) {
      return [];
    }
    return this.reports.map((i) => {
      const { region, report } = i;
      const { impressions, interactions } = report;
      return {
        region: (!region || region === '-') ? 'Unknown' : region,
        impressions,
        interactions,
        engagement: impressions > 0 ? (interactions / impressions) * 100 : 0,
      };
    });
  }

  @Watch('sortBy')
  watchSortBy() {
    this.setRegionTableSort();
  }

  @Watch('sortDesc')
  watchSortDesc() {
    this.setRegionTableSort();
  }

  setRegionTableSort() {
    if (this.$refs.regionTable) {
      this.$refs.regionTable.sortBy = this.sortBy;
      this.$refs.regionTable.sortDesc = this.sortDesc;
    }
  }
}
